import angular from 'angular';
import { find, remove, uniqBy, filter } from 'lodash';
import { ActionTree, MutationTree, GetterTree } from 'angular-store';

import {
  CreateClientOptions,
  UpdateClientOptions,
  DeleteClientOptions
} from '@api/modules/institution-manager';
import { TableState } from '@interfaces';
import { Client } from '@interfaces/client';
import { ClientConfig } from '@interfaces/client-config';
import { Institution } from '@interfaces/institution';
import { Subgroup } from '@interfaces/subgroup';
import { ClientModel } from '@models/client.model';
import { ClientConfigModel } from '@models/client-config.model';
import { HasAccessCheckOptions } from '@services/acl/acl.service';
import { RootState } from '@store/state';

export interface ClientsState {
  loading: boolean;
  items: ClientModel[];
  focus: ClientModel | null;
  table: TableState;
  clientConfig: ClientConfig;
}

export type GetByIdGetter = (id: string) => ClientModel | undefined;

export namespace Getters {
  export type Find = (ref: number | ClientModel) => ClientModel | undefined;
  export type GetById = (id: string) => ClientModel | undefined;
  export type GetByInstitutionIdId = (institutionId: string) => ClientModel[];
}

export namespace Actions {
  export interface List {
    (replace?: boolean): Promise<ClientModel[]>;
  }
}

export namespace Mutations {
  export interface SetFocus {
    (clientId: string | null): void;
  }
}

/** ... */
export interface SetItemsActionOptions {
  items: Client[];
  replace?: false;
}
/** ... */
// export type GetClientActionOptions = GetClientOptions;
export type GetClientActionOptions = {
  clientId: Client['id'];
  institutionId: Institution['id'];
};
/** ... */
export type CreateClientActionOptions = CreateClientOptions;
/** ... */
export type UpdateClientActionOptions = UpdateClientOptions;
/** ... */
export type DeleteClientActionOptions = DeleteClientOptions;
/** ... */
export interface TransferClientLocationActionOptions {
  clientId?: Client['id'];
  clientIds?: Client['id'][];
  subGroupId: Subgroup['id'];
}

export default function ClientsStore(
  $injector: angular.auto.IInjectorService,
  $api: angular.gears.IApiService,
  $api2: angular.gears.IAPI2Service,
  notify: angular.gears.INotifyService
) {
  'ngInject';

  /** ... */
  const hasAccess = (actions: HasAccessCheckOptions) =>
    $injector.get<angular.gears.IAuthService>('$auth').hasAccess(actions);

  /** ... */
  const mapClientData = (items: Client[]) =>
    items.map((item) => new ClientModel(item));

  const state: ClientsState = {
    loading: false,
    items: [],
    focus: null,
    table: { sortedCol: 0, searchText: '' },
    clientConfig: ClientConfigModel.createDefault()
  };

  const getters: GetterTree<ClientsState, RootState> = {
    /**
     * Find a client by reference.
     *
     * @return The matching client, if found.
     */
    find: (state) => (ref: number | ClientModel) => {
      // Assume it's an ID.
      if (typeof ref === 'number') {
        return find(state.items, { id: ref });
      }

      return state.items.find((item) => item === ref || item.id === ref?.id);
    },
    /**
     * Find a client by ID.
     *
     * @return The matching client, if found.
     */
    getById: (state) => (id: Client['id']) => {
      return find(state.items, { id });
    },
    /**
     * Find a client by institution ID.
     *
     * @return The matching client, if found.
     */
    getByInstitutionId: (state) => (institutionId: Institution['id']) => {
      return filter(state.items, { institutionId });
    }
  };

  const actions: ActionTree<ClientsState, RootState> = {
    /**
     * ...
     */
    setItems({ state, rootState, commit }, options: SetItemsActionOptions) {
      let institutions = rootState.institutions.items;

      for (let item of options.items) {
        // TEMP: For testing.
        // assignRandomCustomFieldValues(item, state.clientConfig.customFields);

        // Client Institution.
        if (!item.institution) {
          item.institution = find(institutions, { id: item.institutionId });
        }
      }

      commit(options.replace ? 'SET' : 'ADD', options.items);
    },
    /**
     * List all clients.
     *
     * @param replace ...
     * @return List of all clients.
     */
    async list({ state, commit, rootGetters, dispatch }, replace = false) {
      // reset clients array
      state.items = [];

      let data: Client[] = [];
      let promise: Promise<Client[]> = Promise.resolve([]);

      if (hasAccess('GM:ListClients')) {
        // get all clients
        promise = $api2.gm.listClients();
      } else if (hasAccess('IM:ListClients')) {
        const options = { institutionId: rootGetters.activeInstId };

        const resources = rootGetters['permissions/getResources'](
          'institutionmanager:ListClients',
          'subGroup'
        );

        if (resources === '*') {
          promise = $api2.im.listClients(options);
        } else if (resources) {
          const subgroupIds = Object.values(resources)
            .map(({ resourceValue }) => resourceValue)
            .filter((val) => typeof val === 'string');

          promise = $api2.im.listSubGroupClientsV2({ ...options, subgroupIds });
        } else {
          console.warn('User does not have access to any clients');
        }
      }

      commit('SET_PROPS', { loading: true });

      try {
        data = await promise;
      } catch (err) {
        commit('SET_PROPS', { loading: false });

        throw err;
      }

      // OHbugTEST log
      // console.log(filter(data, { fName: 'OHbugTEST' }));

      await dispatch('setItems', { items: mapClientData(data), replace });

      commit('SET_PROPS', { loading: false });

      return state.items;
    },
    /**
     * ...
     *
     * @return ...
     */
    async getAll({ state, commit, dispatch }) {
      console.error(
        '[store.clients.js] getAll deprecated: please update to list method'
      );

      // get all clients
      if (!hasAccess('GM:ListClients')) {
        return console.warn(
          '[gears-auth] This user does not have access to fetch all clients'
        );
      }

      let data: Client[] = [];

      commit('SET_PROPS', { loading: true });

      try {
        data = await $api2.gm.listClients();
      } catch (err) {
        commit('SET_PROPS', { loading: false });

        throw err;
      }

      //
      await dispatch('setItems', { items: mapClientData(data) });
      //
      await dispatch('analytics/computeForClients', null, true);

      commit('SET_PROPS', { loading: false });

      return state.items;
    },
    /**
     * ...
     *
     * @return ...
     */
    async getForInstitution(
      { state, rootState, commit, dispatch },
      institutionId?: Institution['id']
    ) {
      institutionId = institutionId || rootState.me.institution?.id;

      if (!institutionId) return;

      if (!hasAccess('IM:ListClients')) {
        return console.warn(
          '[gears-auth] This user does not have access to fetch clients for the institution'
        );
      }

      let data: Client[] = [];
      let error: Error | null = null;

      commit('SET_PROPS', { loading: true });

      try {
        data = await $api2.im.listClients({ institutionId });
      } catch (err) {
        error = err;
      }

      if (error) {
        commit('SET_PROPS', { loading: false });

        throw error;
      }

      //
      await dispatch('setItems', { items: mapClientData(data) });

      commit('SET_PROPS', { loading: false });

      return state.items;
    },
    /**
     * Get a single client.
     *
     * @param options ...
     * @return The requested client.
     */
    async get({ state, dispatch }, options: GetClientActionOptions | Client) {
      if (!hasAccess('CM:GetClient')) {
        return console.warn(
          '[gears-auth] This user does not have access to fetch that client'
        );
      }

      const institutionId =
        'institutionId' in options
          ? options.institutionId
          : options.account?.id;

      if (!institutionId) return;

      const clientId = 'clientId' in options ? options.clientId : options.id;

      const data = await $api2.im.getClient({ institutionId, clientId });

      await dispatch('setItems', { items: mapClientData([data]) });

      return find(state.items, { id: data.id });
    },
    /**
     * ...
     *
     * @return ...
     */
    async remove({ commit }, options: DeleteClientActionOptions | any) {
      commit('SET_PROPS', { loading: true });

      // let res = await $api.IM.deleteClient({
      //   clntId: options.clntId,
      //   instId: options.instId
      // });
      //
      // if (!res.data.error) {
      //   throw res;
      // }

      const institutionId = options.institutionId || options.instId;
      const clientId = options.clientId || options.clntId;

      let error: Error | null = null;

      try {
        await $api2.im.deleteClient({ institutionId, clientId });
      } catch (err) {
        error = err;
      }

      if (error) {
        commit('SET_PROPS', { loading: false });

        throw error;
      }

      commit('SET_PROPS', { loading: false });

      commit('REMOVE', clientId);
    },
    /**
     * ...
     *
     * @return ...
     */
    async getOffenderHistory(
      { state, dispatch, rootState, rootGetters },
      clntId
    ) {
      let { tools } = rootState;

      if (!hasAccess('CM:ListOffenderHistory')) {
        return console.warn(
          '[gears-auth] This user does not have access to fetch offender history for that client'
        );
      }

      let client = find(state.items, { id: clntId });

      if (!client) {
        return console.warn(
          `[no-client-found] No client with id ${clntId} found.`
        );
      }

      if (!client.subGroup) {
        return console.warn(
          `[no-client-subgroup] Client with id ${client.id} does not have a subgroup.`
        );
      }

      // let data = [];
      //
      // let res = await $api.clientManager.listOffenderHistory({
      //   instId: client.institutionId,
      //   sbGrpId: client.subGroup?.id,
      //   clntId: client.id
      // });
      //
      // data = res.data;

      // check if we have tools, if not dispatch listTools
      if (!tools?.items.length) await dispatch('tools/list', null, true);

      const options = {
        institutionId: client.institutionId,
        subGroupId: client.subGroup?.id,
        clientId: client.id
      };

      const data = await $api2.cm.listOffenderHistory(options);

      // map tool name to each oh
      // TODO someday create offender history store/model and association to clients store
      client.offenderHistory = data.map((oh) => {
        const val = { ...oh };

        Object.defineProperty(val, 'toolName', {
          get: () => rootGetters['tools/getById'](val.toolId)?.name ?? null
        });

        return val;
      });

      return data;
    },
    /**
     * ...
     *
     * @return ...
     */
    async transferLocations(
      { state, commit },
      options: TransferClientLocationActionOptions
    ) {
      if (!hasAccess('IM:AddClientToSubGroup')) {
        return console.warn(
          '[gears-auth] This user does not have access to add client to sub group'
        );
      }

      if (!options.subGroupId) {
        return console.warn(`[subgroup-id-required] No subgroup ID provided.`);
      }

      const updateClientLocationInStore = function (
        client: ClientModel,
        locationInformation: any
      ) {
        if (
          !locationInformation?.subGroup ||
          !locationInformation?.region ||
          !locationInformation?.zone
        ) {
          return console.error(
            `[location-information-invalid] Location Information in Update Client Location In Store invalid`
          );
        }

        client.subGroup = {
          id: locationInformation.subGroup.id,
          name: locationInformation.subGroup.name,
          description: locationInformation.subGroup.description
        };

        client.region = {
          id: locationInformation.region.id,
          name: locationInformation.region.name,
          description: locationInformation.region.description
        };

        client.zone = {
          id: locationInformation.zone.id,
          name: locationInformation.zone.name,
          description: locationInformation.zone.description
        };

        commit('UPDATE', [client]);
      };

      // transferring a single client
      if (options.clientId) {
        const client = find(state.items, { id: options.clientId });

        if (!client) {
          return console.warn(
            `[no-client-found] No client with id ${options.clientId} found.`
          );
        }

        const data = await $api2.im.addClientToSubGroup({
          institutionId: client.institutionId!,
          subGroupId: options.subGroupId,
          clientId: client.id
        });

        updateClientLocationInStore(client, data);

        return client;
      }
      // transferring multiple clients
      else if (options.clientIds) {
        let clientsNotFound = '';
        let institutionId: any = null;
        const clients: ClientModel[] = [];

        // check validity of client selected and assign reference
        options.clientIds.forEach((clientId) => {
          const client = find(state.items, { id: clientId });

          if (!client) {
            clientsNotFound += clientsNotFound.length
              ? `, ${clientId}`
              : `${clientId}`;
            return;
          }

          if (!institutionId && client) institutionId = client.institutionId;

          clients.push(client);
        });

        // we have an error. could not find client. do not proceed with transfers
        if (clientsNotFound.length)
          return console.warn(
            `[no-client-found] No client with id ${clientsNotFound} found.`
          );

        if (!institutionId)
          return console.error(
            `[no-institution-id-detected] Institution ID not Referenced`
          );

        // transfer clients
        try {
          const data = await $api2.im.addClientToSubGroup({
            institutionId,
            subGroupId: options.subGroupId,
            clientIds: options.clientIds
          });

          // grab first client from list
          const locationInformation = data[0];

          clients.forEach((client) => {
            updateClientLocationInStore(client, locationInformation);
          });

          return clients;
        } catch (err) {
          console.error(err);
          throw err;
        }
      }
    },
    /**
     * ...
     *
     * @return ...
     */
    async create({ commit }, options: CreateClientActionOptions) {
      const data = await $api2.im.createClient(options);

      commit('ADD', [data]);

      return data;
    },
    /**
     * ...
     *
     * @return ...
     */
    async update({ dispatch }, options: UpdateClientActionOptions) {
      const data = await $api2.im.updateClient(options);

      return await dispatch('get', data);
    },
    /**
     * ...
     *
     * @return ...
     */
    async delete({ state }, client) {
      let res = await $api.IM.deleteClient({
        instId: client.institutionId,
        clntId: client.id
      });

      if (res.status !== 204 && res.status !== 200) {
        notify.display(res, 'error');
        return;
      }

      remove(state.items, { id: client.id });
    }
  };

  const mutations: MutationTree<ClientsState> = {
    SET(state, payload: ClientModel[]) {
      state.items = [...payload];
    },
    ADD(state, payload: ClientModel[]) {
      state.items = uniqBy([...payload, ...state.items], 'id');
    },
    UPDATE({ items }, payload: ClientModel[] = []) {
      payload = Array.isArray(payload) ? payload : [payload];

      payload.forEach((item) => {
        if (typeof item !== 'object' || !item.id) {
          return console.warn(
            `[data-store:clients:update] A provided value for client data was not valid.`,
            item
          );
        }

        const client = items.find((client) => client.id == item.id);

        if (!client) {
          return console.warn(
            `[data-store:clients:update] The client with ID "${item.id}" could not be found.`
          );
        }

        for (const key in item) {
          client[key] = item[key];
        }
      });
    },
    UPDATE_ITEM({ items }, { ref = -1, props = {} }) {
      let item = items.find((item) => item == ref || item.id == ref.id);

      if (!item) return;

      Object.keys(props).forEach((key) => {
        item[key] = props[key];
      });
    },
    REMOVE({ items }, id) {
      items.splice(
        items.findIndex((item) => item.id === id),
        1
      );
    },
    //
    SET_PROPS(state, props: Partial<ClientsState> = {}) {
      for (let key in props) {
        if (key === 'clientConfig') {
          state.clientConfig = new ClientConfigModel(props.clientConfig!);
        } else if (key in state) {
          state[key] = props[key];
        }
      }
    },
    //
    SET_FOCUS(state, id) {
      state.focus = state.items.find((item) => item.id == id) || null;
    },
    SET_FOCUS_AND_UPDATE(state, ref = {}) {
      // Check if the provided refernce is an existing client.
      let index = state.items.findIndex(
        (item) => item === ref || item.id == ref.id
      );

      // If it's an invalid refernce, halt and warn the user.
      if (index === -1) {
        return console.warn(
          '[ngStore:clients:setFocusAndUpdate] The client reference provided did not match an existing client.'
        );
      }

      // Grab a refernce to the new focus.
      let client = state.items[index];

      // If the user passed in a config (with potentialy updated properties)
      // update the client's object in the store first.
      if (typeof ref == 'object') {
        state.items[index] = client = Object.assign(client, ref);
      }

      // Finaly, set the focus to the client object.
      state.focus = client;
    },
    CLEAR(state) {
      Object.assign(state, {
        loading: false,
        items: [],
        focus: null,
        table: { sortedCol: 0, searchText: '' },
        clientConfig: ClientConfigModel.createDefault()
      });
    }
  };

  // TEMP: remove once all references to them have been removed.
  Object.defineProperties(mutations, {
    set: { enumerable: true, value: mutations.SET },
    add: { enumerable: true, value: mutations.ADD },
    update: { enumerable: true, value: mutations.UPDATE },
    updateItem: { enumerable: true, value: mutations.UPDATE_ITEM },
    remove: { enumerable: true, value: mutations.REMOVE },
    setProps: { enumerable: true, value: mutations.SET_PROPS },
    setFocus: { enumerable: true, value: mutations.SET_FOCUS },
    setFocusAndUpdate: {
      enumerable: true,
      value: mutations.SET_FOCUS_AND_UPDATE
    }
  });

  return { state, getters, actions, mutations };
}

// region Helper Functions

/**
 * ...
 *
 * @param client ...
 * @param fields ...
 * @return ...
 */
function assignRandomCustomFieldValues(
  client: Client,
  fields: ClientConfigModel['customFields']
) {
  for (const field of Object.values(fields)) {
    let value: unknown = null;

    const n = Math.random();

    if (field.isList) {
      value = field.options!.map(({ value }) => value);
    } else if (field.options) {
      value = field.options[0].value;
    } else if (field.type === 'Boolean') {
      value = n < 0.5;
    } else if (field.type === 'Number') {
      value = Math.round(n * 100);
    } else if (field.type === 'String') {
      value = 'abcdefghijklmnopqrstuvwxyz'
        .split('')
        .sort(() => (Math.random() < 0.5 ? -1 : 1))
        .slice(0, 10)
        .join('');
    }

    client[field.key] = value;
  }
}

// endregion Helper Functions
