import { auto } from 'angular';
import { Store, StoreOptions } from 'angular-store';

import { State, RootState, RootModules } from './state';
import { GettersConfig } from './getters';
import { ActionsConfig } from './actions';
import { MutationsConfig } from './mutations';
import { modules } from './modules';

declare module 'angular' {
  namespace gears {
    type IStoreService = Store<RootState & RootModules>;
  }
}

export namespace Root {
  export type State = RootState & RootModules;
}

/**
 * ...
 */
export function storeConfig($injector: auto.IInjectorService) {
  'ngInject';

  const state = $injector.instantiate<RootState>(State);
  const getters = $injector.invoke(GettersConfig);
  const mutations = $injector.invoke(MutationsConfig);
  const actions = $injector.invoke(ActionsConfig);

  return {
    state,
    getters,
    mutations,
    actions,
    modules
  } as StoreOptions<RootState>;
}
