import { Client } from '@interfaces/client';
import { ClientContact } from '@interfaces/client-contact';
import { Evaluation } from '@interfaces/evaluation';
import { OffenderHistory } from '@interfaces/offender-history';

import { RouteController, Get, Delete, Post, RequestMethod, RouteGroup } from '../route-group';

// region Method Parameter Types

export interface RouteBaseOptions {
  institutionId: string;
  subGroupId: string;
  clientId: string;
}

export interface GetClientCasePlanOptions extends RouteBaseOptions {
  casePlanId: string;
}

export interface GetClientEvaluationOptions extends RouteBaseOptions {
  evaluationId: string;
}

export interface GetClientMediaOptions extends RouteBaseOptions {
  mediaId: string;
}

export interface GetEvaluationMediaOptions extends RouteBaseOptions {
  evaluationId: string;
  mediaId: string;
}

export interface GetOffenderHistoryOptions extends RouteBaseOptions {
  historyId: string;
}

export interface GetReportDocumentOptions extends RouteBaseOptions {
  evaluationId: string;
  reportId: string;
}

export interface GetReportPdfOptions extends RouteBaseOptions {
  evaluationId: string;
  reportId: string;
}

export type ListClientCasePlansOptions = RouteBaseOptions;

export type ListClientContactsOptions = RouteBaseOptions;

export type ListClientEvaluationsOptions = RouteBaseOptions;

export type ListClientEvaluatorsOptions = RouteBaseOptions;

export type ListClientMediaOptions = RouteBaseOptions;

export type ListClientReportsOptions = RouteBaseOptions;

export interface ListEvaluationReportsOptions extends RouteBaseOptions {
  evaluationId: string;
}

export type ListOffenderHistoryOptions = RouteBaseOptions;

export interface ListSubGroupClientsOptions extends RouteBaseOptions {
  institutionId: string;
  subGroupId: string;
}

export type CreateClientCasePlanOptions = RouteBaseOptions;

export type CreateClientEvaluationOptions = RouteBaseOptions;

export interface CreateEvaluationReportOptions extends RouteBaseOptions {
  evaluationId: string;
}

export interface CreateOffenderHistoryOptions extends RouteBaseOptions {
  toolId: string;
  // TODO (Alex) create HistoryData type
  historyData: unknown;
}

export interface EmailReportOptions extends RouteBaseOptions {
  evaluationId: string;
  reportId: string;
}

export interface RevertClientEvaluationOptions extends RouteBaseOptions {
  evaluationId: string;
}

export interface SaveClientEvaluationOptions extends RouteBaseOptions {
  evaluationId: string;
}

export interface SubmitClientEvaluationOptions extends RouteBaseOptions {
  evaluationId: string;
}

export interface UpdateClientCasePlanOptions extends RouteBaseOptions {
  casePlanId: string;
}

export interface UpdateClientCasePlanStatusOptions extends RouteBaseOptions {
  casePlanId: string;
}

export interface UpdateOffenderHistoryOptions extends RouteBaseOptions {
  historyId: string;
  toolId: string;
  // TODO (Alex) create HistoryData reference
  historyData: unknown;
}

export type UploadClientMediaOptions = RouteBaseOptions;

export interface UploadEvaluationMediaOptions extends RouteBaseOptions {
  evaluationId: string;
}

export type UpdateClientOptions = RouteBaseOptions;

export interface UpdateClientContactOptions extends RouteBaseOptions {
  contactId: string;
}

export interface DeleteClientContactOptions extends RouteBaseOptions {
  contactId: string;
}

export interface DeleteClientEvaluationOptions extends RouteBaseOptions {
  evaluationId: string;
}

export interface DeleteClientMediaOptions extends RouteBaseOptions {
  mediaId: string;
}

export interface DeleteEvaluationMediaOptions extends RouteBaseOptions {
  evaluationId: string;
  mediaId: string;
}

export interface DeleteOffenderHistoryOptions extends RouteBaseOptions {
  historyId: string;
}

// endregion Method Parameter Types

@RouteController('client-manager/:institutionId/subgroups/:subGroupId/clients/:clientId')
export class ClientManagerRouteGroup extends RouteGroup {
  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Get('case-plans/:casePlanId')
  readonly getClientCasePlan!: RequestMethod<any, GetClientCasePlanOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Get('evaluations/:evaluationId')
  readonly getClientEvaluation!: RequestMethod<Evaluation, GetClientEvaluationOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Get('media/:mediaId')
  readonly getClientMedia!: RequestMethod<any, GetClientMediaOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Get('evaluations/:evaluationId/media/:mediaId')
  readonly getEvaluationMedia!: RequestMethod<any, GetEvaluationMediaOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Get('history/:historyId')
  readonly getOffenderHistory!: RequestMethod<OffenderHistory, GetOffenderHistoryOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Get('evaluations/:evaluationId/reports/:reportId/doc')
  readonly getReportDocument!: RequestMethod<any, GetReportDocumentOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Get('evaluations/:evaluationId/reports/:reportId/pdf')
  readonly getReportPdf!: RequestMethod<any, GetReportPdfOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Get('case-plans')
  readonly listClientCasePlans!: RequestMethod<any, ListClientCasePlansOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Get('contacts')
  readonly listClientContacts!: RequestMethod<ClientContact[], ListClientContactsOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Get('evaluations')
  readonly listClientEvaluations!: RequestMethod<Evaluation[], ListClientEvaluationsOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Get('evaluators')
  readonly listClientEvaluators!: RequestMethod<any, ListClientEvaluatorsOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Get('media')
  readonly listClientMedia!: RequestMethod<any, ListClientMediaOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Get('reports')
  readonly listClientReports!: RequestMethod<any, ListClientReportsOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Get('evaluations/:evaluationId/reports')
  readonly listEvaluationReports!: RequestMethod<any, ListEvaluationReportsOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Get('history')
  readonly listOffenderHistory!: RequestMethod<any, ListOffenderHistoryOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Get(':institutionId/subgroups/:subGroupId/clients')
  readonly listSubGroupClients!: RequestMethod<any, ListSubGroupClientsOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Post('case-plans')
  readonly createClientCasePlan!: RequestMethod<any, CreateClientCasePlanOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Post('evaluations')
  readonly createClientEvaluation!: RequestMethod<any, CreateClientEvaluationOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Post('evaluations/:evaluationId/reports')
  readonly createEvaluationReport!: RequestMethod<any, CreateEvaluationReportOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Post('history')
  readonly createOffenderHistory!: RequestMethod<any, CreateOffenderHistoryOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Post('evaluations/:evaluationId/reports/:reportId/email')
  readonly emailReport!: RequestMethod<any, EmailReportOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Post('evaluations/:evaluationId/revert')
  readonly revertClientEvaluation!: RequestMethod<any, RevertClientEvaluationOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Post('evaluations/:evaluationId')
  readonly saveClientEvaluation!: RequestMethod<any, SaveClientEvaluationOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Post('evaluations/:evaluationId/submit')
  readonly submitClientEvaluation!: RequestMethod<any, SubmitClientEvaluationOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Post('case-plans/:casePlanId')
  readonly updateClientCasePlan!: RequestMethod<any, UpdateClientCasePlanOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Post('case-plans/:casePlanId/status')
  readonly updateClientCasePlanStatus!: RequestMethod<any, UpdateClientCasePlanStatusOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Post('history/:historyId')
  readonly updateOffenderHistory!: RequestMethod<any, UpdateOffenderHistoryOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Post('media')
  readonly uploadClientMedia!: RequestMethod<any, UploadClientMediaOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Post('evaluations/:evaluationId/media')
  readonly uploadEvaluationMedia!: RequestMethod<any, UploadEvaluationMediaOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Post('')
  readonly updateClient!: RequestMethod<any, UpdateClientOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Post('contacts/:contactId')
  readonly updateClientContact!: RequestMethod<any, UpdateClientContactOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Delete('contacts/:contactId')
  readonly deleteClientContact!: RequestMethod<any, DeleteClientContactOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Delete('evaluations/:evaluationId')
  readonly deleteClientEvaluation!: RequestMethod<any, DeleteClientEvaluationOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Delete('media/:mediaId')
  readonly deleteClientMedia!: RequestMethod<any, DeleteClientMediaOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Delete('evaluations/:evaluationId/media/:mediaId')
  readonly deleteEvaluationMedia!: RequestMethod<any, DeleteEvaluationMediaOptions>;

  /**
   * ...
   *
   * @param options Request options.
   * @return ...
   */
  @Delete('history/:historyId')
  readonly deleteOffenderHistory!: RequestMethod<any, DeleteOffenderHistoryOptions>;
}
