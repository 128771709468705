'use strict';

import angular from 'angular';
import { Controller, Inject } from '@decorators/ngCtrl';

@Controller
class InputSearchBarController {
  @Inject $scope;

  get value() {
    return this.model.$viewValue;
  }

  set value(value) {
    this.model.$setViewValue(value);
  }

  get placeholderText() {
    return this.placeholder ? this.placeholder + '...' : 'Search...';
  }
}

export default angular
  .module('app.inputSearchBar', [])
  .component('inputSearchBar', {
    template: require('./input-search-bar.html'),
    require: { model: 'ngModel' },
    bindings: { placeholder: '=' },
    controller: InputSearchBarController,
    controllerAs: 'vm'
  }).name;
