import angular from 'angular';
import { remove, uniqBy, filter, find, update, forEach } from 'lodash';

import { Client, Institution, TableState } from '@interfaces';
import { ActionTree, MutationTree, GetterTree } from '@modules/angular-store';
import { RootState } from '@store/state';
import {
  CreateInstitutionOptions,
  UpdateInstitutionOptions,
  DeleteInstitutionOptions,
  UpdateInstitutionSuperAdminOptions
} from '@api/modules/gears-manager';

import {
  CreateCustomOffenderHistoryTemplateOptions,
  ListCustomOffenderHistoryTemplatesOptions,
  GetCustomOffenderHistoryTemplateOptions,
  UpdateCustomOffenderHistoryTemplateOptions,
  DeleteCustomOffenderHistoryTemplateOptions
} from '@api/modules/institution-manager';

/**
 * ...
 */
export interface InstitutionState {
  loading: boolean;
  items: Institution[];
  table: TableState;
}

export namespace Getters {}

export namespace Actions {
  export interface GetAll {
    (): Promise<Institution[]>;
  }
}

export namespace Mutations {}

export interface UpdateZonesOptions {
  institutionId: Institution['id'];
  zones: Zone[];
}

/**
 * ...
 */
export type InstitutionsStoreModule = ReturnType<typeof InstitutionsStore>;

export type GetInstitutionActionOptions = string;
export type UpdateInstitutionsActionOptions = string;
export type DeleteInstitutionActionOptions = DeleteInstitutionOptions;
export type UpdateInstitutionActionOptions = UpdateInstitutionOptions;
export type CreateInstitutionActionOptions = CreateInstitutionOptions;
export type UpdateInstitutionSuperAdminActionOptions =
  UpdateInstitutionSuperAdminOptions;

class Location {
  id: any = null;
  name: string = null;
  expanded = false;

  constructor(config = {}) {
    this.id = config.id;
    this.name = config.name;
    this.expanded = config.expanded || false;
  }
}

export class Zone extends Location {
  regions: Region[] = [];

  constructor(config = {}) {
    super(config);

    if (config?.regions?.length) {
      this.regions = config.regions.map((region) => new Region(region));
    }
  }

  get clients() {
    const clients = [];

    for (const { subGroups } of this.regions) {
      for (const { client } of subGroups) {
        clients.push(client);
      }
    }

    return clients;
  }
}

export class Region extends Location {
  subGroups: SubGroup[] = [];

  constructor(config = {}) {
    super(config);

    if (config?.subGroups?.length) {
      this.subGroups = config.subGroups.map(
        (subGroup) => new SubGroup(subGroup)
      );
    }
  }

  get clients() {
    const clients = [];

    for (const { client } of this.subGroups) {
      clients.push(client);
    }

    return clients;
  }
}

export class SubGroup extends Location {
  regionId: string;
  clients: Client[] = [];

  constructor(config = {}) {
    super(config);

    this.regionId = config.regionId;
    this.clients = config.clients || [];
  }
}

function institutionsArray(src: Institution | Institution[]) {
  const arr = Array.isArray(src) ? src : [src];

  arr.forEach((item) => {
    item.zones = (item.zones || []).map((zone) => new Zone(zone));
  });

  return arr;
}

export default function InstitutionsStore(
  $api: angular.gears.IApiService,
  $api2: angular.gears.IAPI2Service,
  notify: angular.gears.INotifyService,
  $reincode: angular.gears.IReincodeService,
  utils: angular.gears.IUtilsService
) {
  'ngInject';

  const state: InstitutionState = {
    loading: false,
    items: [],
    table: { sortedCol: 0, searchText: '' }
  };

  const getters: GetterTree<InstitutionState, RootState> = {
    find: (state: InstitutionState) => (id: string) =>
      state.items.find((item) => item.id == id)
  };

  const actions: ActionTree<InstitutionState, RootState> = {
    async getAll({ state, commit, dispatch }) {
      commit('setProps', { loading: true });

      let institutions: any = [];

      try {
        institutions = (await $api.GM.listInstitutions()).data;
      } catch (err) {
        commit('setProps', { loading: false });
        throw err;
      }

      if (!institutions?.length || !Array.isArray(institutions)) {
        commit('setProps', { loading: false });
        throw 'Error Retrieving Institutions';
      }

      institutions = institutions.filter(({ id }) => id !== 'gifrgears');

      institutions.forEach(
        (i: Institution) => (i.name = $reincode.text(i.name))
      );

      commit('set', institutions);

      dispatch('analytics/computeForInstitutions', null, true);

      commit('setProps', { loading: false });

      return state.items;
    },
    async get(
      { rootState, commit },
      institutionId: GetInstitutionActionOptions
    ) {
      if (!institutionId) {
        if (!rootState.me.institution) return;

        institutionId = rootState.me.institution.id;
      }

      commit('setProps', { loading: true });

      let data: Institution;

      try {
        data = await $api2.im.getInstitution({ institutionId });
      } catch (err) {
        commit('setProps', { loading: false });
        throw err;
      }

      data.name = $reincode.text(data.name);

      const clientConfig = data.clientConfig ?? null;
      // const evaluationConfigs = data.evaluationConfigs ?? null;
      const evaluationConfigs = data.evaluationConfigs ?? null;

      const tools = Array.isArray(data.tools)
        ? data.tools.map((t) => {
            return {
              id: t.id,
              name: t.name,
              publishedCommitId: t.publishedCommitId
            };
          })
        : [];

      // check if institution has custom offender histories templates
      let customOffenderHistoryTemplates = [];

      try {
        customOffenderHistoryTemplates =
          await $api2.im.listCustomOffenderHistoryTemplates({ institutionId });
      } catch (err) {
        commit('setProps', { loading: false });
        throw err;
      }

      // We're being provided back the institution information on profile
      data = {
        ...(data.profile ?? data),
        clientConfig,
        evaluationConfigs,
        tools,
        customOffenderHistoryTemplates
      };

      // Grab Institution Zones
      let zones: any[] = [];

      try {
        zones = await $api2.im.listZones({ institutionId });
        zones = utils.alphabetizeArray(zones, 'name');
      } catch (err) {
        commit('setProps', { loading: false });
        throw err;
      }

      // Grab Institution Regions
      let regions: any[] = [];

      try {
        regions = await $api2.im.listRegions({ institutionId });
        regions = utils.alphabetizeArray(regions, 'name');
      } catch (err) {
        commit('setProps', { loading: false });
        throw err;
      }

      // Grab Institution Sub Groups
      let subGroups: any[] = [];

      try {
        subGroups = await $api2.im.listSubGroups({ institutionId });
        subGroups = utils.alphabetizeArray(subGroups, 'name');
      } catch (err) {
        commit('setProps', { loading: false });
        throw err;
      }

      // Build Zones/Regions/Subgroups Object

      for (const region of regions) {
        region.subGroups = filter(subGroups, { regionId: region.id });
      }

      for (const zone of zones) {
        zone.regions = filter(regions, { zoneId: zone.id });
      }

      commit('add', { ...data, zones });

      commit('setProps', { loading: false });

      // console.log('final inst: ', data);
      return data;
    },
    async getLite(
      { rootState, commit },
      institutionId: GetInstitutionActionOptions
    ) {
      if (!institutionId) {
        if (!rootState.me.institution) return;

        institutionId = rootState.me.institution.id;
      }

      commit('setProps', { loading: true });

      let data: Institution;

      try {
        data = await $api2.im.getInstitution({ institutionId });
      } catch (err) {
        commit('setProps', { loading: false });
        throw err;
      }

      data = {
        ...(data.profile ?? data)
      };

      commit('add', { ...data });

      commit('setProps', { loading: false });

      return data;
    },
    async updateInstitutions(
      { rootState, commit },
      id: UpdateInstitutionsActionOptions
    ) {
      commit('updateInstitutions', {
        users: rootState.users.items,
        clients: rootState.clients.items
      });
    },
    async create({ state, commit }, institution: Institution) {
      if (!institution) throw console.error('No Institution Data Provided');

      let newInst;

      try {
        newInst = (await $api.gearsManager.createInstitution(institution)).data;
      } catch (err) {
        throw err;
      }

      commit('add', newInst);

      return newInst;
    },
    async update({ dispatch }, options: UpdateInstitutionActionOptions) {
      let data;
      try {
        data = await $api2.gm.updateInstitution(options);
      } catch (err) {
        throw err;
      }

      // add institution tools
      if (options.addTools?.length) {
        try {
          await $api2.gm.addInstitutionTools({
            institutionId: options.institutionId,
            tools: options.addTools
          });
        } catch (err) {
          throw err;
        }
      }

      // remove institution tools
      if (options.removeTools?.length) {
        try {
          await $api2.gm.removeInstitutionTools({
            institutionId: options.institutionId,
            tools: options.removeTools
          });
        } catch (err) {
          throw err;
        }
      }

      return await dispatch('get', options.institutionId);
    },
    async updateSuperAdmin(
      { dispatch },
      options: UpdateInstitutionSuperAdminActionOptions
    ) {
      try {
        await $api2.gm.updateInstitutionSuperAdmin(options);
      } catch (err) {
        throw err;
      }

      return await dispatch('get', options.institutionId);
    },
    async delete({ state }, id: DeleteInstitutionActionOptions) {
      const res = await $api.GM.deleteInstitution({ instId: id });

      if (res.status !== 204 && res.status !== 200) {
        return notify.display(res, 'error');
      }

      remove(state.items, { id });
    },
    async createCustomOffenderHistoryTemplate(
      { state },
      options: CreateCustomOffenderHistoryTemplateOptions
    ) {
      if (options.templateData)
        options.templateData = JSON.parse(angular.toJson(options.templateData));

      let newOHTs;
      try {
        newOHTs = await $api2.im.createCustomOffenderHistoryTemplate(options);
      } catch (err) {
        throw err;
      }

      return newOHTs;
    },
    async updateCustomOffenderHistoryTemplate(
      { state },
      options: UpdateCustomOffenderHistoryTemplateOptions
    ) {
      if (options.templateData)
        options.templateData = JSON.parse(angular.toJson(options.templateData));
      let updatedOHT;
      try {
        updatedOHT =
          await $api2.im.updateCustomOffenderHistoryTemplate(options);
      } catch (err) {
        throw err;
      }

      return updatedOHT;
    },
    async deleteCustomOffenderHistoryTemplate(
      { state },
      options: DeleteCustomOffenderHistoryTemplateOptions
    ) {
      // ...
    },
    async listCustomOffenderHistoryTemplates(
      { state },
      options: ListCustomOffenderHistoryTemplatesOptions
    ) {
      // ...
    },
    async getCustomOffenderHistoryTemplate(
      { state },
      options: GetCustomOffenderHistoryTemplateOptions
    ) {
      let customOffenderHistoryTemplate;
      try {
        customOffenderHistoryTemplate =
          await $api2.im.getCustomOffenderHistoryTemplate(options);
      } catch (err) {
        throw err;
      }

      return customOffenderHistoryTemplate;
    }
  };

  const mutations: MutationTree<InstitutionState> = {
    set(state, payload) {
      payload = institutionsArray(payload);

      state.items = [...payload];

      // TEMP
      // state.items.forEach(o => (o.reassessmentType = 'AUTO_ANSWER'));
    },
    add(state, payload) {
      payload = institutionsArray(payload);

      state.items = uniqBy([...payload, ...state.items], 'id');
    },
    updateItem({ items }, payload) {
      const ref = items.find((item) => item.id == payload.id);

      if (!ref) {
        return;
      }

      for (const [key, val] of Object.entries(payload)) {
        ref[key] = val;
      }
    },
    updateZones({ items }, payload: UpdateZonesOptions) {
      const ref = items.find((item) => item.id == payload.institutionId);

      if (!ref || payload.zones || !Array.isArray(payload.zones)) {
        return;
      }

      ref.zones = payload.zones;
    },
    setProps(state, props = {}) {
      for (const i in props) {
        if (i in state) {
          state[i] = props[i];
        }
      }
    },
    CLEAR(state) {
      Object.assign(state, {
        loading: false,
        items: [],
        table: { sortedCol: 0, searchText: '' }
      });
    }
  };

  return {
    state,
    getters,
    actions,
    mutations
  };
}
