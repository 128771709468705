import angular from 'angular';
import pdfObject from 'pdfobject';
import { format } from 'date-fns';
import {
  forEach,
  find,
  filter,
  findIndex,
  maxBy,
  some,
  sortBy,
  isEqual,
  omit
} from 'lodash';

import { AssessmentInstance } from '@interfaces/assessment-instance';
import { Client } from '@interfaces/client';
import { CodesAndScore, AutoAnswer, Tool, ToolData } from '@interfaces/tool';
import { ClientModel } from '@models/client.model';

import { checkConditions } from './check-conditions';

/**
 * ...
 */
export interface StartReassessmentOptions {
  id: string;
  client: Client;
}

/**
 * Evaluation utilities service.
 */
export function EvalUtilsService(
  $http: angular.IHttpService,
  $location: angular.ILocationService,
  $sce: angular.ISCEService,
  $interval: angular.IIntervalService,
  $state: angular.ui.IStateService,
  $api: angular.gears.IApiService,
  $api2: angular.gears.IAPI2Service,
  $ls: angular.gears.ILsService,
  $store: angular.gears.IStoreService,
  $modals: angular.gears.IModalsService,
  $util: angular.gears.IUtilService,
  utils: angular.gears.IUtilsService,
  $reincode: angular.gears.IReincodeService,
  $toolService: angular.gears.IToolService,
  getItems: angular.gears.IGetItemsService,
  notify: angular.gears.INotifyService,
  errorHandler: angular.gears.IErrorHandlerService,
  reportUtils: angular.gears.IReportUtilsService,
  Proration: angular.gears.IProrationService
) {
  'ngInject';

  const _tools = $store.state.tools.items;
  const _clients = $store.state.clients.items;
  const _analytics = $store.state.analytics;
  const _me = $store.state.me;
  const myEvaluationRequests = function () {
    return _me.evaluationRequests;
  };

  // Data Methods

  const getClientEvls = (id: string) => {
    return $store.dispatch('evaluations/getForClient', id);
  };

  const getClientEvaluation = (
    instId: string,
    sbGrpId: string,
    clntId: string,
    evalId: string
  ) => {
    return $store.dispatch('evaluations/get', {
      instId,
      sbGrpId,
      clntId,
      evalId
    });
  };

  const _reverseTableSort = (val: unknown) => {
    return $store.commit('reverseTableSort', val);
  };

  const addEvaluation = (evl: unknown) => {
    return $store.commit('evaluations/add', evl);
  };

  const getToolData = (toolId: string, toolCommitId: string) => {
    return $store.dispatch('tools/getToolData', {
      toolId,
      commitId: toolCommitId
    });
  };

  const getInstitutionCustomOffenderHistoryTemplates = (
    institutionId: string
  ) => {
    return find($store.state.institutions.items, { id: institutionId })
      ?.customOffenderHistoryTemplates;
  };

  const getInstitutionCustomOffenderHistoryTemplate = async (
    institutionId: string,
    templateId: string
  ) => {
    return await $store.dispatch(
      'institutions/getCustomOffenderHistoryTemplate',
      { institutionId, templateId }
    );
  };

  const prohibitEdit = () => this.Util.getYLSLSSuiteOfTools();

  let $evl: AssessmentInstance | null = null;
  /** Keeps track if we've created a fresh evaluation. */
  let $newEvl: boolean | undefined = undefined;
  let $tmpEvl: unknown = undefined;
  const $testingEvl = false;
  let $evlScoreTotal = 0;
  let $reassessmentEvl: unknown = null;
  let $reassessmentOption: unknown = null;

  let $pdfBookmarkTest: unknown = undefined;
  let autoSaving = false;
  // let loadingTool: unknown = undefined;

  /**
   * Aseries of asserters for ensuring relyed-upon assesment data exists during
   * evocation of a specifc function. The data in question will be return from
   * the call of it exists. Otherwise, an error will be thrown.
   */
  const asserters = {
    assessment: () => {
      if (!$evl) {
        throw new Error(
          '[evalUtils:assertAssessment] an assertment for the existance of assessment data failed.'
        );
      }

      return $evl;
    },
    evaluation: () => {
      if (!$evl?.evaluation) {
        throw new Error(
          '[evalUtils:assertAssessment] an assertment for the existance of evaluation data failed.'
        );
      }

      return $evl.evaluation;
    },
    client: () => {
      if (!$evl?.client) {
        throw new Error(
          '[evalUtils:assertAssessment] an assertment for the existance of client data failed.'
        );
      }

      return $evl.client;
    },
    tool: () => {
      if (!$evl?.tool) {
        throw new Error(
          '[evalUtils:assertAssessment] an assertment for the existance of tool data failed.'
        );
      }

      return $evl.tool;
    }
  };

  const evlUtils = {
    evalTimeFirst: false,
    stopSaving: null,
    /**
     * ...
     */
    get evaluation() {
      return $evl;
    },
    set evaluation(val) {
      $evl = val;
    },
    /**
     * ...
     */
    get reassessmentEvl() {
      return $reassessmentEvl;
    },
    /**
     * ...
     */
    get reassessmentOption() {
      return $reassessmentOption;
    },
    /**
     * ...
     */
    get newEvaluation() {
      return $newEvl;
    },
    set newEvaluation(val) {
      $newEvl = val;
    },
    /**
     * ...
     */
    get tempEvaluation() {
      return $tmpEvl;
    },
    /**
     * ...
     */
    get autoSaving() {
      return autoSaving;
    },
    set autoSaving(val) {
      autoSaving = val;
    },
    /**
     * ...
     */
    get client() {
      return $store.state.clients.focus;
    },
    set client(val) {
      if (!val) return;

      $store.commit(
        'clients/SET_FOCUS',
        val.id ? val.id.toString() : val.toString()
      );
    },
    /**
     * ...
     */
    get evlScoreTotal() {
      return $evlScoreTotal;
    },
    /**
     * ...
     */
    get isReassessment() {
      return !!$reassessmentEvl || !!$evl?.evaluation?.reassessmentData;
    },
    /**
     * ...
     */
    get isClientSet() {
      return typeof this.client == 'object' && this.client.id >= 0;
    },
    /**
     * ...
     */
    createTempEvaluation() {
      $tmpEvl = angular.copy($evl.evaluation);
    },
    /**
     * ...
     */
    async startNewEvaluation(client: ClientModel, tool: Tool) {
      $evl = { client };
      $newEvl = true;

      this.client = client;

      let selectedTool = tool;

      if (!selectedTool) {
        $store.commit('SET_LOADING_MESSAGE', 'Loading...');
        $store.commit('SET_IS_LOADING', true);
        await $store.dispatch('tools/list');

        let tools = filter($store.state.tools.items, (tool) => {
          return tool.publishedCommitId;
        });

        tools = sortBy(tools, 'id');

        // Group LS/CMI srv, YLS/CMI srv together with SRV first

        const lsirsv = find(tools, { id: 4 });
        const lscmi = find(tools, { id: 124 });

        if (lsirsv && lscmi) {
          tools.splice(findIndex(tools, { id: 124 }), 1);
          tools.unshift(lscmi);

          tools.splice(findIndex(tools, { id: 4 }), 1);
          tools.unshift(lsirsv);
        }

        const ylscmi = find(tools, { id: 120 });
        const ylscmisrv = find(tools, { id: 105 });

        if (ylscmisrv && ylscmi) {
          tools.splice(findIndex(tools, { id: 120 }), 1);
          tools.unshift(ylscmi);

          tools.splice(findIndex(tools, { id: 105 }), 1);
          tools.unshift(ylscmisrv);
        }
        $store.commit('SET_IS_LOADING', false);

        selectedTool = await $modals.util.generalChoice(
          tools,
          undefined,
          'SELECT TOOL',
          'none',
          false
        );
      }

      if (!selectedTool) return;

      if (selectedTool.publishedCommitId) {
        $evl.toolChosen = {
          toolCommitId: selectedTool.publishedCommitId,
          id: selectedTool.id
        };
      } else {
        const toolCommits = await $api.toolCreator.listToolCommits({
          toolId: selectedTool.id
        });

        let chosenCommit;

        if (find(toolCommits, { status: 'Live' })) {
          chosenCommit = find(toolCommits, { status: 'Live' });
        } else {
          chosenCommit = maxBy(toolCommits, 'createdAt');
        }

        if (chosenCommit) {
          $evl.toolChosen = {
            toolCommitId: chosenCommit.id,
            id: chosenCommit.toolId
          };
        }
      }

      if ($evl.toolChosen) this.evaluationToolChosen();
    },
    /**
     * ...
     */
    async startReassessment({ client, id }: StartReassessmentOptions) {
      $newEvl = true;
      $evl = {};

      // NOTE: May still be null if client is not in the store already.
      // Consider changing.
      this.client = client;
      $evl.client = this.client;
      $reassessmentEvl = await getItems.evaluation(id, this.client);

      if (!$reassessmentEvl) {
        return console.error(
          'START REASSESSMENT: Could not retrieve evaluation'
        );
      }

      if (!$store.state.tools.items?.length)
        await $store.dispatch('tools/list');

      const selectedTool = $store.state.tools.items.find(
        (tool) => tool.id === $reassessmentEvl.toolUsed
      );

      if (!selectedTool) {
        return console.error('START REASSESSMENT: Could not retrieve tool');
      }

      if (selectedTool.publishedCommitId) {
        $evl.toolChosen = {
          toolCommitId: selectedTool.publishedCommitId,
          id: selectedTool.id
        };

        return this.evaluationToolChosen();
      }

      const toolCommits = await $api.toolCreator.listToolCommits({
        toolId: selectedTool.id
      });

      const chosenCommit = find(toolCommits, { status: 'Live' })
        ? find(toolCommits, { status: 'Live' })
        : maxBy(toolCommits, 'createdAt');

      if (!chosenCommit) {
        return;
      }

      $evl.toolChosen = {
        toolCommitId: chosenCommit.id,
        id: chosenCommit.toolId
      };

      // $evl.client = this.client;

      await this.evaluationToolChosen();
    },
    /**
     * ...
     */
    async showEvaluations(row: unknown, noRow: boolean) {
      if (!noRow && row) {
        this.client = row;
      }

      if (!this.client && $store.state.clients.focus) {
        this.client = $store.state.clients.focus;
      }

      if (typeof row === 'number' || typeof parseInt(row, 10) === 'number') {
        // this.client = $store?.state?.clients?.items.find(item => item.id == row);
        this.client = find($store?.state?.clients?.items, { id: row });
      }
      // this.loading = true;

      const evals = await getClientEvls(this.client?.id);

      if (evals) {
        // this.evaluations = evals;
        this.client.evaluations = evals;
      }

      // this.loading = false;
      // this.changeSection('evaluations');
      $state.go('dashboardEvaluations', { clientId: this.client?.id });

      _reverseTableSort(true);
    },
    /**
     * ...
     */
    async editCompletedEvaluationWarning(row: unknown) {
      const warning =
        'You are about to edit a completed evaluation. Once you resubmit the evaluation, reports generated using the old version of the evaluation will be automatically deleted.';

      $modals.settings.warning(warning, row, (proceed) => {
        if (proceed) evlUtils.continueEvaluation(row, true);
      });
    },
    /**
     * ...
     */
    revertEvaluationWarning(fromEvalList: unknown) {
      let evaluation;

      if (fromEvalList) {
        evaluation = fromEvalList;
      } else {
        evaluation = $evl;
      }

      evlUtils.cancelInterval();
      // evlUtils.showEvaluations(null, true);

      $modals.evaluation.revertWarning(evaluation, (evl) => {
        if (!evl) return;
        evlUtils.revertEvaluation(evl);
      });
    },
    /**
     * ...
     */
    async revertEvaluation(evl: unknown) {
      if (!evl.evaluationId) {
        evl.evaluationId = evl.id;
      }

      try {
        const res = $api.evaluations.revert(evl.evaluationId);

        if (res.data.error) {
          throw res;
        }

        $evl = {};
        evlUtils.showEvaluations(null, true);

        const openEvals = _analytics.allOpenEvaluations;

        _analytics.setProps({
          prop: 'allOpenEvaluations',
          val: openEvals + 1
        });

        utils.notify('success', 'Evaluation Successfully Reverted');
      } catch (err) {
        errorHandler(err);
      }
    },
    /**
     * ...
     */
    openInNewWindow(pdf: string) {
      window.open(
        pdfObject.embed(pdf, document.body, {
          nameddest: 'Screen'
        })
      );
      // window.open(pdf);
    },
    /**
     * ...
     */
    parsePDFBookmarks(pdf: string, bookmark: string) {
      let pdfTrusted = $sce.getTrustedHtml(pdf);
      bookmark.replace(' ', '%20');
      pdfTrusted = `${pdfTrusted}#nameddest=${bookmark}`;

      $pdfBookmarkTest = $sce.trustAsHtml(pdfTrusted);
    },
    /**
     * ...
     */
    openPDFBookmark(page: unknown) {
      $modals.settings.openPDF(page, $evl.tool.name, $evl.toolPDF);

      return;

      // let options = {
      //   pdfOpenParams: {
      //     view: 'FitV',
      //     page,
      //     toolbar: 0
      //   },
      //   PDFJS_URL: 'assets/components/pdfjs/build/generic/web/viewer.html'
      // };

      // if (this.isIE || this.isEdge || this.isSafari) {
      //   options.pdfOpenParams.pagemode = 'thumbs';
      //   options.forcePDFJS = true;
      // }

      // pdfObject.embed($evl.pdf, '#pdfobject-container', options);
      // $location.hash('pdfobject-container');
      // this.$anchorScroll();
      // pdfJS
      // window.open(pdfUrl);
    },
    /**
     * ...
     */
    cancelEvaluation() {
      $evl = {};
      $newEvl = false;
      $state.go('dashboardEvaluations');
      _reverseTableSort(true);
    },
    /**
     * ...
     *
     * @deprecated
     */
    mSSavePDF() {
      // const data = $evl?.tool?.pdfBlob ?? null;
      // const name = $evl?.tool?.name ?? '';
      //
      // window.navigator.msSaveOrOpenBlob(data, `${name}.pdf`);
    },
    /**
     * Check if user has agreed to tool's terms.
     *
     * @param agree ...
     */
    toolAgreed(agree: boolean) {
      $evl = asserters.assessment();
      $evl.tool = asserters.tool();

      if (!agree) {
        void $state.go('dashboardEvaluations');

        _reverseTableSort(true);

        return notify.error(
          "You must agree to the Tool's Terms & Conditions to continue."
        );
      }

      addUserAgreement(_me.id, $evl.tool.id);

      void this.finishLoadingTool();
    },
    /**
     * Check if user has view the latest tool changelog.
     */
    toolChangelogViewed() {
      $evl = $evl ?? {};

      const key = `${_me.id}_gtu`;

      let gtused = $ls.get(key);

      if (!gtused) {
        // no gtused found, create first entry
        gtused = [
          {
            toolId: $evl.tool.id,
            toolVersion: $evl.tool.version
          }
        ];
      } else {
        const toolFound = gtused.find((item) => item.toolId === $evl.tool.id);

        if (toolFound) {
          toolFound.toolVersion = $evl.tool.version;
        } else {
          gtused.push({
            toolId: $evl.tool.id,
            toolVersion: $evl.tool.version
          });
        }
      }

      $ls.set(key, gtused);
    },
    /**
     * ...
     */
    async getToolPDF(tool?: AssessmentInstance.ToolData) {
      if (!tool) return;

      const pdfId = tool.liveToolPdfId ?? tool.liveToolPdf?.id;

      if (!pdfId) return;

      const req = $http.get(
        `/api/tool-creator/tools/${tool.id}/tool-pdfs/${pdfId}`,
        { responseType: 'arraybuffer' }
      );

      return (await req)?.data;
    },
    /**
     * ...
     */
    async prepareEvaluation(template: unknown) {
      // console.log('prepareEvaluation', template);

      $evl = $evl ?? {};

      if (template) {
        $evl.reportTemplate = template.data.data.template;
        $evl.reportTemplateId = template.data.data.templateId;
      }

      $evl.client = this.client; // set client
      $evl.user = {
        // set user
        fName: _me.fName,
        lName: _me.lName,
        id: _me.id
      };

      let i;

      if (!$newEvl && !$evl.evaluation) {
        $evl.evaluation = {
          evaluationId: $evl.evaluationId,
          clientId: $evl.client.id,
          clientName: $evl.client.name(),
          evaluatorId: _me.id,
          evaluatorName: _me.fName + ' ' + _me.lName,
          institutionId: $evl.client.institutionId,
          institutionName: $evl.client.institution.name,
          toolUsed: $evl.toolChosen,
          toolName: $evl.tool.name,
          toolVersion: $evl.tool.version,
          toolNotes: {},
          generalNotes: '',
          data: {},
          elapsedTime: $evl.elapsedTime,
          rawScore: null
        };

        for (i = 0; i < $evl.tool.codingFormItems.length; i++) {
          $evl.evaluation.data[$evl.tool.codingFormItems[i].id] = {
            aid: '-',
            qid: $evl.tool.codingFormItems[i].id,
            score: '-',
            text: '-'
          };
        }
      } else if ($newEvl) {
        // if it's a new evaluation, POST it so we can get an id
        $evl.evaluation = {
          evaluationId: '',
          clientId: $evl.client.id,
          clientName: $evl.client.name(),
          evaluatorId: _me.id,
          evaluatorName: _me.lName + ', ' + _me.fName,
          institutionId: $evl.client.institutionId,
          institutionName: $evl.client.institution.name,
          toolUsed: $evl.toolChosen,
          toolName: $evl.tool.name,
          toolVersion: $evl.tool.version,
          toolNotes: {},
          generalNotes: '',
          data: {},
          elapsedTime: 0
        };

        for (i = 0; i < $evl.tool.codingFormItems.length; i++) {
          $evl.evaluation.data[$evl.tool.codingFormItems[i].id] = {
            aid: '-',
            qid: $evl.tool.codingFormItems[i].id,
            score: '-',
            text: '-'
          };
        }

        const payload = {
          toolId: $evl.toolChosen,
          clientId: $evl.client.id,
          offenderHistoryId: $evl.offenderHistory?.id
            ? $evl.offenderHistory.id
            : $evl.offenderHistoryId
        };

        const saveNewEvalRes = await $api.clientManager.createClientEvaluation(
          {
            instId: this.client.institutionId,
            sbGrpId: this.client?.subGroup?.id,
            clntId: this.client.id
          },
          payload
        );

        if (saveNewEvalRes.status === 200) {
          const newEvlId = saveNewEvalRes.data.id;
          const newEvl = await $api.CM.getClientEvaluation({
            instId: this.client.institutionId,
            sbGrpId: this.client?.subGroup.id,
            clntId: this.client.id,
            evalId: newEvlId
          });
          $evl.evaluation.evaluationId = newEvlId;
          $evl.evaluation.id = newEvlId;
          addEvaluation($reincode.fullObject(newEvl.data));
        } else {
          evlUtils.cancelEvaluation();
          errorHandler(saveNewEvalRes);
        }
      }

      $state.go('dashboardEvaluationsPerform');

      if (!$newEvl || $evl.evaluation.backup) {
        this.manuallySaveEvaluation();
      }
      this.startSavingEvaluation();
    },
    /**
     * ...
     */
    async evaluationToolChosen(isTest = false) {
      $evl = $evl ?? {};
      $evl.toolChosen = $evl.toolChosen ?? {};

      if ($testingEvl) {
        isTest = true;
        Reflect.deleteProperty(this, 'testingEvaluation');
      }

      $store.commit('SET_LOADING_MESSAGE', 'Retrieving Tool');
      $store.commit('SET_IS_LOADING', true);
      $store.commit('SET_PROHIBIT_INPUT', true);
      // this.retrievingTool = true;

      // updated toolCommitId key
      if (!$evl.toolChosen.toolCommitId) {
        // we're given a commit itself, not the latest version of the tool
        if ($evl.toolChosen.toolId && typeof $evl.toolChosen.id === 'string') {
          $evl.toolChosen.toolCommitId = $evl.toolChosen.id;
          $evl.toolChosen.id = $evl.toolChosen.toolId;
        } else if (
          'publishedCommitId' in $evl.toolChosen &&
          typeof $evl.toolChosen.id
        ) {
          $evl.toolChosen.toolCommitId = $evl.toolChosen.publishedCommitId;
        }
      } else if (
        typeof $evl.toolChosen.id === 'string' &&
        $evl.toolChosen.toolId
      ) {
        $evl.toolChosen.toolCommitId = $evl.toolChosen.id;
        $evl.toolChosen.id = $evl.toolChosen.toolId;
      }

      // cast interview date time into date object
      if ($evl.evaluation?.interview?.dateTime)
        $evl.evaluation.interview.dateTime = format(
          new Date($evl.evaluation.interview.dateTime),
          'MM/dd/yyyy'
        );

      let toolCommitId;
      let toolId;

      if ($evl.toolChosen.toolCommitId) {
        toolCommitId = $evl.toolChosen.toolCommitId;
        toolId = $evl.toolChosen.id;
      }

      if (!toolCommitId && $evl.evaluation && $evl.evaluation.toolCommitId) {
        toolCommitId = $evl.evaluation.toolCommitId;
        toolId = $evl.evaluation.toolUsed;
      }

      if (!toolCommitId && typeof $evl.toolChosen.id === 'string') {
        toolCommitId = $evl.toolChosen.id;
      }

      if (!toolCommitId) {
        notify.display('Could not determine tool commit id', 'error');
        return;
      }

      const tool = await getToolData(toolId, toolCommitId);

      $evl.tool = tool ?? {};

      // if (!$evl.tool.id) $evl.tool.id = $evl.toolChosen.id;
      // $evl.tool.commitId = toolCommitId;
      // if (toolData.data.liveDescriptionPdf)
      //   $evl.tool.liveDescriptionPdf = toolData.data.liveDescriptionPdf;
      // if (toolData.data.liveDescriptionPdfId)
      //   $evl.tool.liveDescriptionPdfId = toolData.data.liveDescriptionPdfId;
      // if (toolData.data.liveToolPdf)
      //   $evl.tool.liveToolPdf = toolData.data.liveToolPdf;
      // if (toolData.data.liveToolPdfId)
      //   $evl.tool.liveToolPdfId = toolData.data.liveToolPdfId;

      // OFFENDER HISTORY

      if ($evl.tool.offenderHistory) {
        // check if institution has custom offender history instead of default
        const customOffenderHistoryTemplates =
          getInstitutionCustomOffenderHistoryTemplates(
            this.client.institution.id
          );

        if (
          customOffenderHistoryTemplates?.length &&
          find(
            customOffenderHistoryTemplates,
            (coht) => coht.tool?.id === $evl?.tool.id
          )
        ) {
          const customOffenderHistoryTemplate = find(
            customOffenderHistoryTemplates,
            (coht) => coht.tool?.id === $evl?.tool.id
          );

          const template = await getInstitutionCustomOffenderHistoryTemplate(
            this.client.institution.id,
            customOffenderHistoryTemplate.id
          );

          $evl.tool.offenderHistory = template.templateData;
        }

        // get most recent client offender histories
        this.client.offenderHistory = await getItems.clientOffenderHistory(
          this.client
        );

        if (
          !some(this.client.offenderHistory, {
            toolId: $evl.tool.id
          })
        ) {
          // new offender history must be created
          $store.commit('SET_LOADING_MESSAGE', 'Offender History Required');
          $store.commit('SET_IS_LOADING', false);
          $store.commit('SET_PROHIBIT_INPUT', false);

          const offenseClassifications = await $util.getOffenseClassifications(
            this.client.institution.id
          );

          const newOffenderHistory = await $modals.evaluation.offenderHistory(
            this.client,
            $evl.tool,
            offenseClassifications
          );

          if (!newOffenderHistory) {
            // this.selectClient(this.client);
            $store.commit('SET_IS_LOADING', false);

            notify.display(
              'warning',
              `Must complete an Offender History before starting an evaluation for the ${$evl.tool.name}`
            );

            $store.commit('SET_PROHIBIT_INPUT', false);
            $state.go('dashboardClient', { id: this.client.id });
            $store.commit('SET_IS_LOADING', false);
            $store.commit('SET_PROHIBIT_INPUT', false);

            return;
          }

          $store.commit('SET_IS_LOADING', true);

          this.client.offenderHistory.push(newOffenderHistory);
          $evl.offenderHistory = newOffenderHistory.offenderHistory;
          $evl.offenderHistoryId = newOffenderHistory.id
            ? `${newOffenderHistory.id}`
            : newOffenderHistory.id;
        } else if (
          $evl.evaluation?.offenderHistoryId &&
          some(this.client.offenderHistory, {
            id: $evl.evaluation?.offenderHistoryId
          })
        ) {
          // offender history is already associated to evaluation, grab that one
          const offenderHistoryData =
            await $api.clientManager.getOffenderHistory({
              instId: this.client.institutionId,
              sbGrpId: this.client?.subGroup?.id,
              clntId: this.client.id,
              historyId: $evl.evaluation?.offenderHistoryId
            });

          if (offenderHistoryData.status === 200) {
            $evl.offenderHistory = offenderHistoryData.data.historyData;
          } else {
            $evl.offenderHistory = [];
          }
        } else {
          // offender history(ies) exist, find the latest updated one
          const toolOH = filter(
            this.client.offenderHistory,
            (oh) => oh.toolId === $evl.tool.id
          );

          const latestOffenderHistory = maxBy(toolOH, 'updatedAt');

          const evaluationOffenderHistory =
            await $api.clientManager.getOffenderHistory({
              instId: this.client.institutionId,
              sbGrpId: this.client?.subGroup?.id,
              clntId: this.client.id,
              historyId: latestOffenderHistory.id
            });

          if (evaluationOffenderHistory.status === 200) {
            $evl.offenderHistory = evaluationOffenderHistory.data.historyData;
            $evl.offenderHistoryId = latestOffenderHistory.id
              ? `${latestOffenderHistory.id}`
              : latestOffenderHistory.id;
          } else {
            $evl.offenderHistory = [];
          }
        }

        $store.commit('SET_IS_LOADING', true);
        $store.commit('SET_PROHIBIT_INPUT', true);
        $store.commit('SET_LOADING_MESSAGE', 'Retrieving Tool');
      }

      // TOOL AGREEMENT

      if (!$evl.tool.agreement || $evl.tool.agreement.length == 0) {
        return await this.finishLoadingTool(isTest);
      }

      const toolAgreementAccepted = getUserAgreements(_me.id).some(
        (toolId) => toolId === $evl.tool.id
      );

      if (toolAgreementAccepted) {
        return await this.finishLoadingTool(isTest);
      }

      $store.commit('SET_IS_LOADING', false);
      $store.commit('SET_PROHIBIT_INPUT', false);

      const res = await $modals.settings.termsAndConditions();

      if (!res) {
        notify.warning('Must Agree to Terms And Conditions to Continue');
        $state.go('dashboardEvaluations');
        return;
      }

      return this.toolAgreed(true);
    },
    /**
     * ...
     */
    async manuallySaveEvaluation() {
      $evl.evaluation.evaluatorId = _me.id;
      const evalData = $reincode.fullObject(
        angular.copy($evl.evaluation),
        true
      );

      // make sure we're tracking offender history id
      if ($evl?.offenderHistoryId && !evalData.offenderHistoryId)
        evalData.offenderHistoryId = $evl.offenderHistoryId
          ? `${$evl.offenderHistoryId}`
          : $evl.offenderHistoryId;

      const evalSaveRes = await $api.clientManager.saveClientEvaluation(
        {
          instId: this.client.institutionId,
          sbGrpId: this.client?.subGroup?.id,
          clntId: this.client.id,
          evalId: $evl.evaluation.evaluationId
        },
        {
          data: evalData
        }
      );
      this.autoSaving = false;
      if (evalSaveRes.status === 200 || evalSaveRes.status === 204) {
        $evl.evaluation.backup = evalSaveRes.data?.backup;
      } else {
        notify.display(
          'There was an error auto-saving your evaluation.',
          'error',
          undefined,
          'Error - Auto-Saving Evaluation'
        );
      }
      // $http
      //   .post(`/api/evaluations/${$evl.evaluation.evaluationId}/save`, {
      //     data: $evl.evaluation
      //   })
      //   .then(evalSaveRes => {
      //     this.autoSaving = false;
      //     if (evalSaveRes.data.error) {
      //       notify.error({
      //         title: 'Error - Auto-Saving Evaluation',
      //         message: 'There was an error auto-saving your evaluation.',
      //         positionX: 'center',
      //         templateUrl: 'assets/components/notification-template.html'
      //       });
      //     } else if (evalSaveRes.data.data) {
      //       $evl.evaluation.backup = evalSaveRes.data.data.backup;
      //     }
      //   });
    },
    /**
     * ...
     */
    startSavingEvaluation() {
      this.evalTimeFirst = new Date();
      // var exists = false;

      this.stopSaving = $interval(onSaveInterval, 60000);
    },
    /**
     * ...
     */
    cancelInterval() {
      // cancels the stop saving interval
      $evl = {};
      $reassessmentEvl = null;
      $interval.cancel(this.stopSaving);
    },
    /**
     * ...
     */

    async chooseClient(prevFcn: string) {
      const client = await $modals.settings.chooseClient(_clients);

      if (
        prevFcn !== 'createEvaluation' &&
        prevFcn !== 'createEvaluationRequest'
      ) {
        return;
      }

      $store.commit(
        'clients/SET_FOCUS',
        client.id ? client.id.toString() : null
      );
      $store.commit('clients/updateItem', {
        age: utils.dateTime.calcAge(client.dob)
      });

      this.client = $store.state.clients.focus;

      if (prevFcn === 'createEvaluation') {
        evlUtils.createEvaluation();
      } else if (prevFcn === 'createEvaluationRequest') {
        evlUtils.openCreateEvaluationRequest();
      }
    },
    /**
     * ...
     */
    async confirmClient(prevFcn: string) {
      const client = await $modals.settings.confirmClient(this.client, prevFcn);

      if (!client) {
        evlUtils.openChooseClientModal(prevFcn);
      } else if (prevFcn === 'createEvaluation') {
        evlUtils.createEvaluation();
      } else if (prevFcn === 'createEvaluationRequest') {
        evlUtils.openCreateEvaluationRequest();
      }
    },
    /**
     * ...
     */
    // handle coming from All Evaluations page
    createEvaluationFromAllEvals() {
      if (!evlUtils.clientSet) {
        evlUtils.chooseClient('createEvaluation');
      } else {
        evlUtils.confirmClient('createEvaluation');
      }
    },
    /**
     * ...
     */
    openCreateEvaluationRequestFromAllEvals() {
      if (!evlUtils.clientSet) {
        evlUtils.chooseClient('createEvaluationRequest');
      } else {
        evlUtils.confirmClient('createEvaluationRequest');
      }
    },
    /**
     * ...
     */
    openChooseClientModal(prevFcn: string) {
      evlUtils.chooseClient(prevFcn);
    },
    /**
     * ...
     */
    // end handle coming from All Evaluations page
    createEvaluation() {
      $evl = {};
      $newEvl = true; // variable to check on tool chosen to see if we need to create a new evaluation
      $state.go('dashboardEvaluationsPerform');
    },
    /**
     * ...
     */
    // continue performing evaluation that hasn't been completed
    async continueEvaluation(evaluation: unknown) {
      this.client = evaluation.clientId
        ? evaluation.clientId
        : evaluation.client.id;

      if (!this.client)
        this.client = _.find(_clients, { id: evaluation?.clientId });

      if (!this.client) {
        const clients = await $store.dispatch('clients/list');
        this.client = find(clients, { id: evaluation?.clientId });
      }

      if (!this.client)
        return notify.display('Could not find client.', 'error');

      const evlRes = await getClientEvaluation(
        this.client.institutionId,
        this.client?.subGroup?.id,
        this.client.id,
        evaluation.id
      );

      if (!evlRes) return;
      if (
        evlRes.status === 'COMPLETED' &&
        _.find(prohibitEdit, { id: evlRes.toolUsed })
      )
        return notify.display('Cannot edit a completed evaluation', 'error');

      $newEvl = false;
      evlRes.evaluationData = $reincode.fullObject(evlRes.evaluationData);

      $evl = {
        evaluation: evlRes.evaluationData,
        evaluationId: evlRes.id,
        toolChosen: {
          id: evlRes.toolUsed,
          toolCommitId: evlRes.toolCommitId
        },
        client: this.client
      };

      // $reassessmentEvl = evlRes.evaluationData.reassessmentData || null;
      if ($state.current.name !== 'dashboardEvaluationsPerform')
        $state.go('dashboardEvaluationsPerform');
      await evlUtils.createTempEvaluation();
      await evlUtils.evaluationToolChosen();
    },
    /**
     * ...
     */
    async openWizard(itemWizard: unknown) {
      const answer = await $modals.evaluation.wizard(
        itemWizard,
        $evl.tool.dictionary,
        $evl.toolPDF
      );

      if (answer) {
        this.answerFromWizard(answer);
      }
    },
    /**
     * ...
     */
    // coming from wizard modal with an answer
    answerFromWizard(answer: unknown) {
      // if no score has been given yet
      if ($evl.evaluation.data === null || $evl.evaluation.data === undefined)
        $evl.evaluation.data = [];

      let answerId;
      if (answer.answer.answer.questionAnswerToSelect)
        answerId = answer.answer.answer.questionAnswerToSelect;

      const answerScore = answer.answer.answer.answerScore;

      // check if the question is in a childTool
      let questionAddress;
      let questionAddressArray;
      forEach($evl.evaluation.data, (val, key) => {
        if (key.includes(answer.answer.question)) {
          questionAddress = key;
          questionAddressArray = key.split('>');
          return false;
        }
      });

      let codingFormItem;
      if (questionAddressArray) {
        if (questionAddressArray.length === 1) {
          codingFormItem = find($evl.tool.codingFormItems, {
            id: answer.answer.question
          });
        } else {
          // it's in a child tool
          // find child tool
          // the second to last item will be the tool's id
          // let toolId = questionAddressArray[questionAddressArray.length - 2];
          const questionId =
            questionAddressArray[questionAddressArray.length - 1];
          // let tool = find($evl.tool.childTools, (ct) => {
          //   return ct.id === toolId;
          // });
          codingFormItem = find($evl.tool.codingFormItems, {
            id: questionId
          });
        }
      } else {
        console.error('No Question Address Array Found | answerFromWizard()');
      }

      let baseAnswer;
      if (codingFormItem) {
        baseAnswer = answerId
          ? find(codingFormItem.codesAndScore, { id: answerId })
          : find(codingFormItem.codesAndScore, { score: answerScore });
      } else {
        console.error('No Coding Form Item Found | answerFromWizard()');
      }
      if (baseAnswer && questionAddress) {
        if (!$evl.evaluation.data[questionAddress]) {
          // first time this question has been answered
          $evl.evaluation.data[questionAddress] = {
            qid: answer.answer.question,
            aid: baseAnswer.id,
            text: baseAnswer.text,
            score: baseAnswer.score,
            comment: '',
            wizard: {}
          };
        } else {
          $evl.evaluation.data[questionAddress].qid = answer.answer.question;
          $evl.evaluation.data[questionAddress].aid = baseAnswer.id;
          $evl.evaluation.data[questionAddress].text = baseAnswer.text;
          $evl.evaluation.data[questionAddress].score = baseAnswer.score;
        }
        // $evl.evaluation.data[answer.answer.question].answer = answer.answer;
        const answerObj = {
          wqid: answer.answer.answer.wizardQuestionId,
          waid: answer.answer.answer.id,
          score: answer.answer.answer.answerScore,
          baseQuestionId: answerId,
          answers: answer.answers
        };
        if (answer.variables) {
          answerObj.variables = answer.variables;
        }
        $evl.evaluation.data[questionAddress].wizard = answerObj;
      } else {
        if (!baseAnswer)
          console.error('No Base Answer Found | answerFromWizard()');
        if (!questionAddress)
          console.error('No Question Address Found | answerFromWizard()');
      }
      this.calculateEvaluationScoreTotal();
    },
    /**
     * ...
     */
    calculateEvaluationTool(
      tool: ToolData,
      evalDataForTool: AssessmentInstance.Evaluation['data']
    ) {
      // Call this function again for child tools
      const evaluation = asserters.evaluation();

      tool.omitted = 0;
      tool.answeredCount = 0;
      tool.unansweredCount = 0;

      if (!tool.address) tool.address = `${tool.id}>`;
      if (!evaluation.toolScores) evaluation.toolScores = {};
      evaluation.toolScores[tool.address] = {
        score: 0,
        riskCategory: null
      };

      // putting this first processes the deepest tools first
      if (tool.childTools && tool.childTools.length) {
        forEach(tool.childTools, (ct) => {
          const toolEvalData = {};
          forEach(evaluation.data, (val, key) => {
            if (key.includes(`${ct.address}Q-`)) toolEvalData[key] = val;
          });

          ct.parentTool = tool;
          this.calculateEvaluationTool(ct, toolEvalData);
        });
      }

      let toolScore;
      let toolRiskCategory;

      if (tool.address) {
        if (!evaluation.toolScores) evaluation.toolScores = {};
        evaluation.toolScores[tool.address] = {
          score: 0,
          riskCategory: null
        };
        toolScore = evaluation.toolScores[tool.address].score;
        toolRiskCategory = evaluation.toolScores[tool.address].riskCategory;
      }
      let oneAnswerFound = false;

      // GROUPINGS CALCULATIONS
      if (
        tool.codingFormItemGroupings &&
        Object.keys(tool.codingFormItemGroupings).length
      ) {
        forEach(tool.codingFormItemGroupings, (g) => {
          g.omitted = 0;
          if (!('groupings' in evaluation)) evaluation.groupings = {};
          evaluation.groupings[g.id] = {
            name: g.name,
            score: 0,
            omitted: 0
          };
        });
      }
      // end GROUPINGS CALCULATIONS

      // Set of global evaluation constants
      // to track incase proration is required.
      const evlConstants = {
        highestScoreOfAnswered: 0,
        lowestScoreOfAnswered: 0,
        highestScoreOfUnanswered: 0,
        lowestScoreOfUnanswered: 0
      };

      // Utility function for determining highest
      // and lowest scores for an individual question.
      const getExtremes = (scoreItems) => {
        let highest = 0,
          lowest = 0;

        scoreItems.forEach((item) => {
          highest = item.score > highest ? item.score : highest;
          lowest = item.score < lowest ? item.score : lowest;
        });

        return {
          highest,
          lowest
        };
      };

      // Reset total possible score since we are recalculating
      if (tool.riskCategories?.length) {
        const maxRiskCat = maxBy(tool.riskCategories, 'high');

        if (maxRiskCat) {
          maxRiskCat.high = maxRiskCat.originalHigh;
          tool.highestScore = maxRiskCat.high;
        }
      }

      // Calculate evaluation scores
      forEach(evalDataForTool, (item) => {
        // Get reference to question configuration
        let qid = item.qid ? angular.copy(item.qid) : null;
        qid = qid ? qid.split('>') : null;
        qid = qid ? qid[qid.length - 1] : null;

        const toolQuestionData = find(tool.codingFormItems, { id: qid });

        // check toolQuestionData show conditions to set toolQuestionData.hide value
        if (toolQuestionData.show) {
          if (typeof toolQuestionData.show === 'string')
            toolQuestionData.show = JSON.parse(toolQuestionData.show);
          if (typeof toolQuestionData.show === 'object')
            toolQuestionData.hide = !this.checkConditions(
              toolQuestionData.show.conditions
            );
        }

        if (!toolQuestionData) return;

        if (item.aid === 'A-unanswer') item.aid = '-';

        if (item.aid && item.aid !== '-') {
          oneAnswerFound = true;
          toolQuestionData.touched = true;
          toolQuestionData.valid = true;
          if (typeof item.score === 'number') {
            $evlScoreTotal += item.score;
            toolScore += item.score;
            if (toolQuestionData?.groupings?.length) {
              forEach(toolQuestionData.groupings, (g) => {
                if (!evaluation?.groupings[g]) return;
                evaluation.groupings[g].score += item.score;
              });
            }
          }

          // Check for omitted question that changes the overall possible score
          if (
            find(toolQuestionData.codesAndScore, { id: item.aid })?.omit &&
            maxBy(tool.riskCategories, 'high')
          ) {
            maxBy(tool.riskCategories, 'high').high += parseInt(
              find(toolQuestionData.codesAndScore, { id: item.aid }).omit,
              10
            );
            tool.highestScore = maxBy(tool.riskCategories, 'high').high;
          }

          // Check for generally omitted question and track in case there is a maximum omitted rule
          if (item.text === 'Omit' || item.text === 'omit') {
            tool.omitted++;
            if (toolQuestionData?.groupings?.length) {
              forEach(toolQuestionData.groupings, (g) => {
                const cfig = find(tool.codingFormItemGroupings, { id: g });
                if (cfig) cfig.omitted++;
                const evalGrouping = evaluation.groupings[g];
                if (evalGrouping) evalGrouping.omitted++;
              });
            }
          }
          if (!toolQuestionData.ignoreCount) tool.answeredCount++;

          // Modify global constants related to ANSWERED question.
          const scoreExtremes = getExtremes(toolQuestionData.codesAndScore);
          evlConstants.highestScoreOfAnswered += scoreExtremes.highest;
          evlConstants.lowestScoreOfAnswered += scoreExtremes.lowest;
        } else {
          if (!toolQuestionData.ignoreCount) tool.unansweredCount++;
          if (toolQuestionData.touched) toolQuestionData.touched = false;
          if (toolQuestionData.valid) toolQuestionData.valid = false;

          // Modify global constants related to UNANSWERED question.
          const scoreExtremes = getExtremes(toolQuestionData.codesAndScore);
          evlConstants.highestScoreOfUnanswered += scoreExtremes.highest;
          evlConstants.lowestScoreOfUnanswered += scoreExtremes.lowest;
        }
      });

      // check the tool for rules and required questions
      tool.invalidItems = [];

      // add answeredCount, unansweredCount, and omitted to parent if it exists
      if (tool.parentTool) {
        tool.parentTool.answeredCount += tool.answeredCount;
        tool.parentTool.unansweredCount += tool.unansweredCount;
        tool.parentTool.omitted += tool.omitted;
        // add score to parent tool's total
        if (tool.parentTool.address) {
          if (evaluation.toolScores[tool.parentTool.address].score) {
            evaluation.toolScores[tool.parentTool.address].score += toolScore;
          } else {
            evaluation.toolScores[tool.parentTool.address] = {
              score: toolScore,
              riskCategory: null
            };
          }
        }
      }

      // If proration rules exist
      const proration = tool.prorate;
      if (proration && proration.rules) {
        proration.rules.forEach((item) => {
          switch (item.ruleType) {
            case 'Minimum Answers Required':
              if (tool.answeredCount < item.amount) {
                tool.invalidItems.push(
                  `Minimum Number of Answers Required = ${item.amount}`
                );
              }
              break;
            default:
          }
        });
      }

      // check tool for scoring rules to set any invalid items to the tool.invalidItems array
      const invalidGroupings = [];
      if (tool.rules && tool.rules.length) {
        forEach(tool.rules, (rule) => {
          switch (rule.ruleType) {
            case 0:
              // maximum omit allowance
              if (!rule.group && tool.omitted > rule.maxOmitAllowance) {
                tool.invalidItems.push(`${rule.ruleText}`);
                $toolService.setToolOmitsValidity(tool, evaluation.data, false);
              } else if (
                rule.group &&
                find(tool.codingFormItemGroupings, { id: rule.group })
                  ?.omitted > rule.maxOmitAllowance
              ) {
                // Tool has question groupings
                // Hare PCL-R is the tool that uses groupings
                tool.invalidItems.push(`${rule.ruleText}`);
                invalidGroupings.push(rule.group);
              } else if (!rule.group) {
                // Omitted items, if marked invalid need to be marked valid again
                $toolService.setToolOmitsValidity(tool, evaluation.data, true);
              } else if (rule.group) {
                // Omitted items, if marked invalid need to be marked valid again
                $toolService.setToolGroupingsOmitsValidity(
                  tool,
                  evaluation.data,
                  rule.group,
                  true
                );
              }
              break;

            case 1:
              // must answer specific questions

              break;

            case 2:
              // must answer minimum number of questions
              if (tool.answeredCount < rule.minimumAnswersRequired) {
                tool.invalidItems.push(`${rule.ruleText}`);
              }
              break;

            default:
              break;
          }
        });

        // check invalidGroupings to set final validity after process
        if (invalidGroupings?.length) {
          forEach(invalidGroupings, (group) => {
            $toolService.setToolGroupingsOmitsValidity(
              tool,
              evaluation.data,
              group,
              false
            );
          });
        }
      }

      // check for explicitly required coding form items
      const missingRequiredItems = [];
      forEach(tool.codingFormItems, (cfi) => {
        if (!cfi.required) return;
        const cfiID = cfi.longAddress ? cfi.longAddress : cfi.id;
        const evalAnswer = evalDataForTool[cfiID];
        if (!evalAnswer) return;
        if (!evalAnswer.aid || evalAnswer.aid == '-')
          missingRequiredItems.push(cfiID);
      });
      if (missingRequiredItems.length)
        tool.invalidItems.push('Required Questions Missing');

      // check for comment requirements
      if (!evaluation?.toolNotes) evaluation.toolNotes = {};
      if (tool.commentsRequired && !evaluation?.toolNotes[tool.address]?.length)
        tool.invalidItems.push('Comment Required');

      // set validity for tool
      tool.valid = !tool.invalidItems.length;
      const setParentToolValidity = function (t) {
        if (oneAnswerFound) t.touched = true;
        t.valid = tool.valid;
        if (t.parentTool) setParentToolValidity(t.parentTool);
      };
      if (tool.parentTool) setParentToolValidity(tool.parentTool);

      //region PRORATION
      if (proration && proration.proratingRequired) {
        evaluation.rawScore = $evlScoreTotal;

        const constantsConfig = [];
        const addConstant = (label, value) => {
          constantsConfig.push({
            label,
            value
          });
        };

        // Create answer-related groups for use with score calculation
        proration.operations.forEach((item) => {
          switch (item.label) {
            case 'scoreOfAnswered':
              addConstant('scoreOfAnswered', evaluation.rawScore);
              break;
            case 'highestScoreOfAnswered':
              addConstant(
                'highestScoreOfAnswered',
                evlConstants.highestScoreOfAnswered
              );
              break;
            case 'lowestScoreOfAnswered':
              addConstant(
                'lowestScoreOfAnswered',
                evlConstants.lowestScoreOfAnswered
              );
              break;
            case 'highestScoreOfUnanswered':
              addConstant(
                'highestScoreOfUnanswered',
                evlConstants.highestScoreOfUnanswered
              );
              break;
            case 'lowestScoreOfUnanswered':
              addConstant(
                'lowestScoreOfUnanswered',
                evlConstants.lowestScoreOfUnanswered
              );
              break;
            default:
          }
        });

        $evlScoreTotal = Proration({
          ops: proration.operations,
          constants: constantsConfig
        });
      }
      // PRORATE TABLES
      if (tool.tableProrates?.length) {
        evaluation.rawScore = $evlScoreTotal;
        forEach(tool.tableProrates, (pt) => {
          evaluation.tableProrates[pt.evalScoreVariable] =
            this.parseToolScoreTable(pt);
          // if (pt.evalScoreVariable === 'totalScore') {
          //   $evlScoreTotal = this.parseToolScoreTable(pt);
          // } else {
          //   $evl.evaluation.tableProrates[pt.evalScoreVariable] = this.parseToolScoreTable(pt);
          // }
        });
        // check for totalScore evaluation prorate to set proratedRiskCategory
        if (
          evaluation.tableProrates.totalScore ||
          evaluation.tableProrates.totalScore === 0
        ) {
          const prScore = evaluation.tableProrates.totalScore.includes('.')
            ? parseFloat(evaluation.tableProrates.totalScore)
            : parseInt(evaluation.tableProrates.totalScore, 10);
          let prRiskCat;
          forEach($evl.tool.riskCategories, (rc) => {
            if (prRiskCat) return;
            if (prScore >= rc.low && prScore <= rc.high) prRiskCat = rc.name;
          });
          evaluation.proratedRiskCategory = prRiskCat;
        }
      }
      //endregion PRORATION

      // DYNAMIC SCORING TABLES
      if (tool.dynamicScoringTables?.length) {
        forEach(tool.dynamicScoringTables, (dt) => {
          $evl.evaluation.dynamicScoringTables[dt.evalScoreVariable] =
            this.parseToolScoreTable(dt);
        });
      }
      //endregion DYNAMIC SCORING TABLES

      // reset progressBar values
      if (tool.progressBar) tool.progressBar.value = 0;
      if (toolRiskCategory && !oneAnswerFound) {
        toolRiskCategory = null;
        toolScore = '-';
      }

      if (oneAnswerFound) {
        tool.touched = true;
        // evalDataForTool.score = $evlScoreTotal;
        for (let i = 0; i < tool.riskCategories.length; i++) {
          // calculate progress bar type, if they are high risk
          if (
            toolScore >= tool.riskCategories[i].low &&
            toolScore <= tool.riskCategories[i].high
          ) {
            if (tool.address) {
              // childTool
              if (tool.progressBar) tool.progressBar.value = i + 1;
              toolRiskCategory = tool.riskCategories[i].name;
              $evl.evaluation.toolScores[tool.address].riskCategory =
                toolRiskCategory;
              $evl.evaluation.toolScores[tool.address].score = toolScore;
            } else {
              // rootTool
              this.progressBar.value = i + 1;
              evaluation.riskCategory = tool.riskCategories[i].name;
            }
          }
        }
      }

      // offset because progressbar only handles positive numbers
      if (!tool.address)
        this.scoreOffset = $evlScoreTotal + Math.abs(tool.lowestScore);
    },
    /**
     * ...
     */
    updateToolRiskCategories(tool: ToolData) {
      const evaluation = asserters.evaluation();

      // find childTools riskCategories
      forEach(tool.childTools, (ct) => {
        // if we have a toolScores entry
        const toolScoresEntry = find(evaluation.toolScores, (val, key) => {
          return key === ct.address;
        });

        if (toolScoresEntry) {
          // figure out which riskCategory they fall into
          if (ct.riskCategories && ct.riskCategories.length) {
            forEach(ct.riskCategories, (rc, index) => {
              if (
                toolScoresEntry.score >= rc.low &&
                toolScoresEntry.score <= rc.high
              ) {
                toolScoresEntry.riskCategory = rc.name;
                if (ct.progressBar) ct.progressBar.value = index + 1;
              }
            });
          }
        }

        // if we have a bar graph
        if (ct.bar) {
          let childTool;
          let score;
          forEach(ct.bar.labels, (label, index) => {
            childTool = some(ct.childTools, { flyoutName: label })
              ? find(ct.childTools, { flyoutName: label })
              : find(ct.childTools, { name: label });
            score = null;
            if (childTool) {
              score = find($evl.evaluation.toolScores, (val, key) => {
                return key === childTool.address;
              });
              // find corresponding risk category based on score
              if (score && score.riskCategory) {
                score = score.score;
                if (
                  childTool.riskCategories &&
                  childTool.riskCategories.length
                ) {
                  forEach(childTool.riskCategories, (rc, rcIndex) => {
                    if (score >= rc.low && score <= rc.high) {
                      ct.bar.data[index] = rcIndex + 1;
                    }
                  });
                }
              } else {
                // if no risk category is found, set bar to 0
                ct.bar.data[index] = 0;
              }
            }
          });
        }
      });

      // handle root tool score update and risk category
      if (tool.riskCategories && tool.riskCategories.length) {
        forEach(tool.riskCategories, (rc, index) => {
          if ($evlScoreTotal >= rc.low && $evlScoreTotal <= rc.high) {
            this.progressBar.value = index + 1;
            if (tool.address)
              $evl.evaluation.toolScores[tool.address].riskCategory = rc.name;
            $evl.evaluation.riskCategory = rc.name;
          }
        });
      }
    },
    /**
     * ...
     */
    calculateEvaluationScoreTotal() {
      this.calculatingEvaluation = true;

      const tool = $evl.tool;
      const evalData = $evl.evaluation.data;
      const toolEvalData = {};
      forEach(evalData, (val, key) => {
        // if key isn't a map to a childTool, include in root tool eval data
        if (!key.includes('>')) toolEvalData[key] = val;
      });

      $evlScoreTotal = 0;
      this.calculateEvaluationTool(tool, toolEvalData);

      this.updateToolRiskCategories(tool);

      this.calculatingEvaluation = false;
      $evl.evaluation.score = $evlScoreTotal;
    },
    /**
     * ...
     */
    forIncludedTools(callback: unknown) {
      // Must have tool loaded to check for validity
      if (!$evl?.tool) {
        return false;
      }

      const forTool = (tool, parent) => {
        const stopLoop = callback(tool, parent);

        if (stopLoop) {
          return true;
        }

        if (!tool.childTools?.length) {
          return false;
        }

        for (const ct of tool.childTools) {
          const stop = forTool(ct, tool);

          if (stop) {
            return true;
          }
        }
      };

      forTool($evl.tool);
    },
    /**
     * ...
     */
    evaluationInvalid() {
      // Must have tool loaded to check for validity
      if (!$evl?.tool) {
        return false;
      }

      const invalidItems = [];

      const childToolsExist = !!$evl.tool.childTools?.length;

      this.forIncludedTools((tool) => {
        if (!tool.invalidItems?.length) {
          return;
        }

        for (const item of tool.invalidItems) {
          const val = childToolsExist
            ? `${tool.flyoutName || tool.name} | ${item}`
            : `${item}`;

          invalidItems.push(val);
        }
      });

      return invalidItems;
    },
    /**
     * ...
     */
    randomizeEvaluation(times = 1, randomize = false) {
      // let times = angular.element('#randomize-eval-times')[0].value;
      // if (typeof parseInt(times, 10) !== 'number') times = 1;

      if (typeof times != 'number') {
        times = 1;
      }

      times = Math.round(times);

      console.log({ times, randomize });

      const randEvlOutputs = {
        tool: $evl.tool.name,
        toolId: $evl.tool.id,
        toolCommitId: $evl.evaluation.toolCommitId,
        evaluations: [],
        riskCategoryBreakdown: {}
      };

      let missed, maxMissed, minAnswers;
      const generateRandomText = function (length) {
        let str = '';
        const chars =
          'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789 ';
        for (let i = 0; i < length; i++) {
          str += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return str;
      };

      const runTool = (tool) => {
        forEach(tool.codingFormItems, (cfi) => {
          const cfiID = cfi.longAddress ? cfi.longAddress : cfi.id;

          let answerQuestion = randomize
            ? Math.floor(Math.random() * 2) === 0
            : true;

          let useWizard = Math.floor(Math.random() * 2) === 0;

          // TODO (Alex) for unit testing item wizard
          useWizard = false;
          if (missed >= maxMissed) {
            answerQuestion = true;
          }

          if (answerQuestion) {
            if (cfi.itemWizard && useWizard) {
              this.openWizard(cfi, true);
            } else if (cfi.codesAndScore.length > 1) {
              let rand = Math.floor(
                Math.random() * cfi.codesAndScore.length + 1
              );
              if (rand === 1) rand++; // don't purposefully select a none answer
              if (
                cfi.codesAndScore[rand - 1].text === 'Omit' ||
                cfi.codesAndScore[rand - 1].text === 'omit'
              )
                rand--;
              $evl.evaluation.data[cfiID].aid = cfi.codesAndScore[rand - 1].id;
              this.updateEvaluationQuestionData(cfiID, cfi.codesAndScore);
            } else if (cfi.fillIn) {
              $evl.evaluation.data[cfiID].fillIn =
                `Random Fill in for ${cfi.riskFactor}: ` +
                generateRandomText(Math.floor(Math.random() * 100) + 50);
            }
          } else {
            $evl.evaluation.data[cfiID].aid = '-';
            this.updateEvaluationQuestionData(cfiID, cfi.codesAndScore);
            missed++;
          }
        });

        if (tool.childTools) {
          forEach(tool.childTools, (ct) => {
            runTool(ct);
          });
        }
      };

      const parseOutput = function (e) {
        const questionAnswers = [];
        forEach(e.tool.codingFormItems, (cfi) => {
          questionAnswers.push({
            questionText: cfi.riskFactor,
            answerText: e.evaluation.data[cfi.id].text,
            score: e.evaluation.data[cfi.id].score
          });
        });

        randEvlOutputs.evaluations.push({
          questions: questionAnswers,
          rawScore: e.evaluation.rawScore,
          totalScore: e.evaluation.score,
          riskCategory: e.evaluation.riskCategory
        });

        if (!randEvlOutputs.riskCategoryBreakdown[e.evaluation.riskCategory]) {
          randEvlOutputs.riskCategoryBreakdown[e.evaluation.riskCategory] = 1;
        } else {
          randEvlOutputs.riskCategoryBreakdown[e.evaluation.riskCategory]++;
        }
      };

      for (let i = 0; i < times; i++) {
        if ($evl.tool.prorate && $evl.tool.prorate.rules) {
          forEach($evl.tool.prorate.rules, (rule) => {
            if (rule.ruleType === 'Minimum Answers Required') {
              minAnswers = rule.amount;
              maxMissed = $evl.tool.codingFormItems.length - minAnswers;
            }
          });
        }

        missed = 0;
        runTool($evl.tool);
        parseOutput($evl);
      }

      localStorage.setItem('randEvlOutputs', JSON.stringify(randEvlOutputs));
    },
    /**
     * ...
     */
    /**
     * ...
     *
     * @param answer ...
     * @param aa ...
     * @param isArray ...
     * @return ...
     */
    runAutoAnswer(answer: string, aa: AutoAnswer, isArray?: boolean) {
      if (!$evl?.evaluation) {
        throw new Error(
          'could not find any evaluation data on current assessment.'
        );
      }

      const findQuestion = function (arr: string[]) {
        let t = $evl!.tool!;

        for (const id of arr) {
          if (id.includes('Q-')) {
            const question = find(t.codingFormItems, { id });

            if (question) return question;
          } else if (typeof parseInt(id, 10) === 'number') {
            for (const ct of t.childTools) {
              if (ct.id === parseInt(id)) t = ct;
            }
          }
        }

        return null;
      };

      const aaArray = answer.split('>');
      const question = findQuestion(aaArray);

      if (!question) {
        return console.error('AutoAnswer:Could not find question');
      }

      let answerId = find(aaArray, (i) => i.includes('A-'));

      if (!answerId) {
        return console.error('AutoAnswer:Answer ID could not be found');
      } else if (answerId.includes('unanswer')) {
        answerId = '-';
      }

      const eq = $evl.evaluation.data[question.longAddress];

      if (eq.aid === answerId) {
        return;
      }

      // make sure we don't already have it answered as the auto answer
      // check if a custom message is given, if not give generic one
      if (isArray) {
        const answerText = find(question.codesAndScore, { id: answerId })?.text;

        eq.aid = answerId;

        this.updateEvaluationQuestionData(
          question.longAddress,
          question.codesAndScore
        );

        return { answerText, riskFactor: question.riskFactor };
      }

      if (aa.message && aa.message.length && !aa.noMessage) {
        aa.message = aa.message.replace(/</g, '{');
        aa.message = aa.message.replace(/>/g, '}');
        notify.clear();
        notify.display(
          aa.message,
          'success',
          8000,
          'Scoring Rule Notice',
          'center'
        );
      } else if (!aa.noMessage) {
        const answerText = find(question.codesAndScore, { id: answerId })?.text;

        notify.clear();
        notify.display(
          `${question.riskFactor} > ${answerText}`,
          'success',
          8000,
          'Scoring Rule Notice',
          'center'
        );
      }

      eq.aid = answerId;

      this.updateEvaluationQuestionData(
        question.longAddress,
        question.codesAndScore
      );
    },
    /**
     * ...
     */
    /**
     * ...
     *
     * @param questionId ...
     * @param questionCodesAndScore ...
     * @return ...
     */
    updateEvaluationQuestionData(
      questionId: string,
      questionCodesAndScore: CodesAndScore[]
    ) {
      if (!$evl?.evaluation) {
        throw new Error(
          'could not find any evaluation data on current assessment.'
        );
      }

      if (some(questionCodesAndScore, 'overrideRiskCategory')) {
        $evl.evaluation.overrideRiskCategory = null;
      }

      // let addressMap = questionId.includes('>') ? questionId.split('>') : null;

      let qstn = $evl.evaluation.data[questionId];

      if (qstn) {
        qstn.qid = questionId;
      } else {
        qstn = { qid: questionId };
      }

      for (const item of questionCodesAndScore) {
        if (qstn.aid !== item.id) continue;

        qstn.text = item.text;
        qstn.score = item.score;
        qstn.fillIn = '';

        // check for overrideRiskCategory
        if (item.overrideRiskCategory) {
          $evl.evaluation.overrideRiskCategory = item.overrideRiskCategory;
        }

        // check for autoAnswers and warnings.
        if (Array.isArray(item.warningCheck) && item.warningCheck.length) {
          // for (const wc of item.warningCheck ?? []) {
          for (let i = 0; i < item.warningCheck.length; i++) {
            const wc = item.warningCheck[i];
            if (
              wc.conditions?.length &&
              this.checkConditions(wc.conditions, wc.conditionModifier)
            ) {
              wc.warning = wc.warning.replace(/</g, '{');
              wc.warning = wc.warning.replace(/>/g, '}');

              notify.clear();
              notify.display(
                wc.warning,
                'warning',
                true,
                'Scoring Rule Recommendation',
                'center'
              );
            }
          }
        }

        // for (const aa of item.autoAnswer ?? []) {
        if (Array.isArray(item.autoAnswer) && item.autoAnswer?.length) {
          for (let i = 0; i < item.autoAnswer?.length; i++) {
            const aa = item.autoAnswer[i];

            if (
              aa.conditions?.length &&
              !this.checkConditions(aa.conditions, aa.conditionModifier)
            ) {
              continue;
            }

            if (!Array.isArray(aa.answer)) {
              this.runAutoAnswer(aa.answer, aa);

              continue;
            }

            // Track if one answer was auto answered so we don't double notify
            let answerAutoAnswered = false;
            let answerInfo: any = null;

            for (const answer of aa.answer) {
              answerInfo = this.runAutoAnswer(answer, aa, true);

              if (answerInfo) answerAutoAnswered = true;
            }

            if (!answerAutoAnswered) continue;

            if (aa.noMessage) {
              continue;
            }

            let message = '';

            if (aa.message && aa.message.length) {
              aa.message = aa.message.replace(/</g, '{').replace(/>/g, '}');

              message = aa.message;
            } else {
              message = `${answerInfo.riskFactor} > ${answerInfo.answerText}`;
            }

            notify.clear();

            notify.display(
              message,
              'success',
              8000,
              'Scoring Rule Notice',
              'center'
            );
          }
        }

        // set fillInAnswerSelected variable so we can display the text box on the evaluation
        qstn.fillInAnswerSelected = item.fillIn ? item.fillIn : null;
      }

      // Answer was chosen by user, therefore any existing wizard line of
      // questioning is irrelevant.
      delete qstn.wizard;

      this.calculateEvaluationScoreTotal();
    },
    /**
     * ...
     */
    // show evaluation submit agreement
    evaluationAgreement() {
      let scoreFound = false;

      for (const key in $evl.evaluation.data) {
        if ($evl.evaluation.data[key].score != '-') {
          scoreFound = true;
        }
      }

      if (scoreFound) {
        $modals.evaluation.agreement($evl, (agreement) => {
          if (!agreement) {
            notify.display(
              'You must agree to the statements in order to submit the evaluation for scoring.',
              'error'
            );
            return;
          }
          this.submitEvaluation();
        });
      } else {
        notify.warning('At least one score must be given for the evaluation.');
      }
    },
    /**
     * ...
     */
    // submit evaluation for completion
    async submitEvaluation() {
      this.processing = true;

      const evaluation = angular.copy($evl);

      const evalObj = $reincode.fullObject(evaluation.evaluation, true);

      this.cancelInterval();

      // var scoreTotal = 0;

      for (const [key, val] of Object.entries(evalObj.data)) {
        if (key == 'generalNotes' || !parseInt(val.score, 10)) {
          continue;
        }

        // scoreTotal += val.score;
      }

      if (!evalObj.evaluationId) {
        this.processing = false;
        return;
      }

      let response;
      try {
        response = await $api.clientManager.submitClientEvaluation(
          {
            instId: this.client.institutionId,
            sbGrpId: this.client?.subGroup.id,
            clntId: this.client.id,
            evalId: evalObj.evaluationId
          },
          { data: evalObj }
        );
      } catch (err) {
        this.processing = false;
        errorHandler(err);
        $state.go('dashboardClient', { id: this.client.id });
      }

      const matchedReminders = $store.state.reminders.items.filter(
        ({ data }) =>
          data.toolId == evalObj.toolUsed && data.clientId == evalObj.clientId
      );

      if (matchedReminders.length) {
        const markAsComplete = await $modals.confirm.markMatchedReminders();

        if (markAsComplete) {
          for (const reminder of matchedReminders) {
            await $store.dispatch('reminders/check', {
              reminderId: reminder.id,
              completed: true
            });
          }
        }
      }

      if (response.status !== 200 && response.status !== 204) {
        this.processing = false;
        errorHandler(response);
        $state.go('dashboardClient', { id: this.client.id });
      }

      notify.display('Successfully Submitted Evaluation', 'success');
      // update evaluation request if it exists
      const evalRequest = find(myEvaluationRequests(), {
        evaluationId: evalObj.evaluationId
      });

      if (evalRequest) {
        $store.commit('me/removeEvlReqs', { id: evalRequest.id });
      }

      // _analytics.allOpenEvaluations--;
      $store.commit('analytics/SET_PROPS', {
        allOpenEvaluations: _analytics.allOpenEvaluations - 1
      });

      // var evalFound = false;

      const evals = await $api.clientManager.listClientEvaluations({
        instId: this.client.institutionId,
        sbGrpId: this.client?.subGroup.id,
        clntId: this.client.id
      });

      $store.commit('evaluations/setFocus', evalObj.evaluationId);
      if (evals.status === 200) this.client.evaluations = evals.data;
      $state.go('dashboardReports');

      if (evaluation.reportTemplate) {
        let evalForReport = find(this.client?.evaluations, {
          id: evalObj.evaluationId
        });

        if (!evalForReport) {
          evalForReport = {
            id: evalObj.evaluationId,
            updatedAt: new Date(),
            toolUsed: evalObj.toolUsed
          };
        }

        reportUtils.evaluation = evalForReport;
        reportUtils.generateReport(evalForReport);
        this.processing = false;
      }

      this.processing = false;
    },
    /**
     * ...
     */
    toggleComment(question: unknown) {
      question.comment = !question.comment;
    },
    /**
     * ...
     */
    async finishLoadingTool(isTest = false, referrer = null, toolOnly = false) {
      $evl = asserters.assessment();
      // $evl.client = asserters.client();
      $evl.evaluation = $evl.evaluation ? asserters.evaluation() : undefined;
      $evl.tool = asserters.tool();

      if (!$evl.tool) {
        throw new Error(
          '[eval-utils:finishLoadingTool] assessment tool data has not been set.'
        );
      }

      $store.commit('SET_LOADING_MESSAGE', 'Loading Tool');
      $store.commit('SET_IS_LOADING', true);
      $store.commit('SET_PROHIBIT_INPUT', true);

      // const getItemsService = getItems;
      // const getToolJsonService = getToolJson;
      const t = this;

      // set up tool notes
      if ($evl.evaluation && !$evl.evaluation.toolNotes)
        $evl.evaluation.toolNotes = {};

      $evl.client = this.client ?? {}; // set client

      // Set up additionalInformation section of evaluation with offenderHistory fields
      if (
        ($evl.tool.id === 120 || $evl.tool.id === 156) &&
        $evl.offenderHistory &&
        !toolOnly
      ) {
        // for YLS/CMI, Hare PCL-R, find client's normative type from offender history
        if ($evl?.evaluation?.additionalInformation?.clientNormativeType) {
          $evl.client.clientNormativeType =
            $evl.evaluation.additionalInformation.clientNormativeType;
        } else {
          let clientNormativeField;

          $evl.offenderHistory.sections.forEach(({ sections }) =>
            sections.forEach(({ fields }) =>
              fields.forEach((field) => {
                if (field.key === 'clientNormativeType')
                  clientNormativeField = field;
              })
            )
          );

          $evl.client.clientNormativeType = clientNormativeField?.model || null;
        }
      } else if (
        ($evl.tool.id === 124 || $evl.tool.id === 157) &&
        $evl.offenderHistory &&
        !toolOnly
      ) {
        // for LS/CMI and LSI-R, setting and occupationalStanding
        let occupationalStanding;
        let setting;
        forEach($evl.offenderHistory.sections, (section) => {
          forEach(section.sections, (sect) => {
            if (sect.key === 'setting') {
              setting = {
                setting: sect.model,
                subOption: sect.subOption
              };
            }
            forEach(sect.fields, (field) => {
              if (field.key === 'occupationalStanding')
                occupationalStanding = field;
            });
          });
        });

        $evl.client.occupationalStanding = occupationalStanding
          ? occupationalStanding.model
          : null;
        $evl.client.setting = setting ? setting.setting.label : null;
      }

      $evl.user = {
        // set user
        fName: _me.fName,
        lName: _me.lName,
        id: _me.id
      };

      // check for customRiskCategories and find matching criteria to set in toolJson.riskCategories
      // if we have customRiskCategories, find the matching criteria and set to the tool's riskCategories array
      const checkCustomRiskCategories = function (arr) {
        forEach(arr, (crc) => {
          if (crc.criteria) {
            let criteriaMatch = true;
            forEach(crc.criteria, (val, key) => {
              switch (key) {
                case 'sex':
                  if (val !== $evl.client.sex) criteriaMatch = false;
                  break;
                case 'clientNormativeType':
                  if (val !== $evl.client.clientNormativeType)
                    criteriaMatch = false;
                  break;
              }
            });
            crc.criteriaMatch = criteriaMatch;
          }
        });
        return arr;
      };

      const parseTool = (tool) => {
        if (tool.customRiskCategories?.length) {
          tool.customRiskCategories = checkCustomRiskCategories(
            tool.customRiskCategories
          );
          if (find(tool.customRiskCategories, { criteriaMatch: true }))
            tool.riskCategories = find(tool.customRiskCategories, {
              criteriaMatch: true
            }).categories;
        }

        // set originalHigh and lowest/highest scores
        if (!toolOnly) {
          const maxRiskCat = maxBy(tool.riskCategories, 'high');
          if (maxRiskCat) maxRiskCat.originalHigh = maxRiskCat.high;

          tool.lowestScore = 0;
          tool.highestScore = 0;

          tool.riskCategories.forEach((item) => {
            if (item.low <= tool.lowestScore) tool.lowestScore = item.low;
            if (item.high >= tool.highestScore) tool.highestScore = item.high;
          });
        }

        if (tool.childTools?.length)
          forEach(tool.childTools, (ct) => parseTool(ct));
      };

      parseTool($evl.tool);

      const parseCodingFormItems = (toolJson) => {
        for (const cfi of toolJson.codingFormItems) {
          // set evaluation data to blank
          const cfiAddress = cfi.longAddress || cfi.id;

          if (!toolOnly && $evl.evaluation.data[`${cfiAddress}`]) {
            const evalDataItem = $evl.evaluation.data[`${cfiAddress}`];

            if (!evalDataItem.aid || evalDataItem.aid === '-') {
              forEach(evalDataItem, (val, key) => {
                if (key === 'qid') {
                  val = cfi.id;
                } else if (key === 'aid' || key === 'score' || key === 'text') {
                  val = '-';
                }
              });
            }
          } else if (!toolOnly) {
            $evl.evaluation.data[`${cfiAddress}`] = {
              aid: '-',
              qid: cfi.id,
              score: '-',
              text: '-'
            };
          }

          // parse groupings array
          if (
            'groupings' in cfi &&
            !Array.isArray(cfi.groupings) &&
            typeof cfi.groupings === 'string'
          )
            cfi.groupings = JSON.parse(cfi.groupings);
        }
        if (toolJson.childTools?.length)
          toolJson.childTools.forEach((ct) => parseCodingFormItems(ct));
      };

      // set up $evl.evaluation
      if (!$newEvl && !$evl.evaluation && !toolOnly) {
        $evl.evaluation = {
          evaluationId: $evl.evaluationId,
          clientId: $evl.client.id,
          clientName: $evl.client.name(),
          evaluatorId: _me.id,
          evaluatorName: `${_me.fName} ${_me.lName}`,
          institutionId: $evl.client.institutionId,
          institutionName: $evl.client.institution.name,
          toolUsed: $evl.toolChosen.id,
          toolCommitId: $evl.toolChosen.publishedCommitId,
          toolName: $evl.tool.name,
          toolVersion: $evl.tool.version,
          generalNotes: '',
          interview: {
            interviewer: _me.id,
            dateTime: dateTimeNow()
          },
          data: {},
          elapsedTime: $evl.elapsedTime
        };
      } else if ($newEvl && !toolOnly) {
        // if it's a new evaluation, POST it so we can get an id
        $evl.evaluation = {
          evaluationId: '',
          clientId: $evl.client.id,
          clientName: $evl.client.name(),
          evaluatorId: _me.id,
          evaluatorName: `${_me.lName}, ${_me.fName}`,
          institutionId: $evl.client.institutionId,
          institutionName: $evl.client.institution.name,
          toolUsed: $evl.toolChosen.id,
          toolCommitId: $evl.toolChosen.publishedCommitId
            ? $evl.toolChosen.publishedCommitId
            : $evl.toolChosen.toolCommitId, // key updated for backend
          toolName: $evl.tool.name,
          toolVersion: $evl.tool.version,
          generalNotes: '',
          interview: {
            interviewer: _me.id,
            dateTime: dateTimeNow()
          },
          data: {},
          elapsedTime: 0
        };
      }

      $evl.tool.answeredCount = 0;
      $evl.tool.unansweredCount = 0;
      $evl.tool.omitted = 0;

      $evlScoreTotal = $newEvl ? '-' : $evl.tool.lowestScore;

      this.progressBar = {
        type: 'danger',
        max: $evl.tool.riskCategories.length,
        value: 0
      };

      if (!toolOnly) parseCodingFormItems($evl.tool);

      //endregion Child Tools
      buildBarGraphs();

      //region tableProrates and dynamicScoringTables
      if ($evl.tool?.tableProrates?.length) {
        // for (let i in $evl.tool.tableProrates) {
        forEach($evl.tool.tableProrates, (table, i) => {
          // let table = $evl.tool.tableProrates[i];

          if (!$evl.evaluation.tableProrates) {
            $evl.evaluation.tableProrates = {};
          }

          $evl.evaluation.tableProrates[table.evalScoreVariable] = 0;
          $evl.tool.tableProrates[i] = t.makeToolTable(table);
        });
      }

      if ($evl.tool?.dynamicScoringTables?.length) {
        forEach($evl.tool.dynamicScoringTables, (table, i) => {
          if (!$evl.evaluation.dynamicScoringTables)
            $evl.evaluation.dynamicScoringTables = {};
          $evl.evaluation.dynamicScoringTables[table.evalScoreVariable] = 0;
          $evl.tool.dynamicScoringTables[i] = t.makeToolTable(table);
        });
      }
      //endregion tableProrates and dynamicScoringTables

      if (!$newEvl && $evl.evaluation && !toolOnly)
        this.calculateEvaluationScoreTotal();

      const pdfData = await this.getToolPDF($evl.tool);
      if (pdfData && pdfData.err) {
        //this.loadingTool = false;
        $store.commit('SET_IS_LOADING', false);
        $store.commit('SET_PROHIBIT_INPUT', false);
        return;
      }

      $evl.toolPDF = pdfData;

      if (pdfData) {
        const options = {
          pdfOpenParams: {
            view: 'FitV',
            page: '1',
            pagemode: 'none',
            toolbar: 0
          },
          PDFJS_URL: 'assets/components/pdfjs/build/generic/web/viewer.html'
        };

        if (this.isIE || this.isEdge || this.isSafari)
          options.forcePDFJS = true;

        $evl.pdf = URL.createObjectURL(
          new Blob([pdfData], { type: 'application/pdf' })
        );

        $evl.trustedPdf = $sce.trustAsResourceUrl($evl.pdf);

        pdfObject.embed($evl.pdf, '#pdfobject-container', options);
      }

      // region report template
      // grab the report template
      let template = await getItems.toolTemplate($evl.toolChosen);
      if (template && template.err) {
        if (!template.err.data.error) {
          if (isTest) {
            console.warn(
              'No template data was found, but this is permitted when testing a tool. Continuing...'
            );
          } else {
            notify.error('No Report Template Found For Tool');
            // return;
          }
        } else {
          errorHandler(template.err);
          // return;
        }
      }

      if (template) {
        const templates = await getItems.templateCommits(template);

        if (templates.err) {
          evlUtils.cancelEvaluation();

          $store.commit('SET_IS_LOADING', false);
          $store.commit('SET_PROHIBIT_INPUT', false);
          errorHandler(templates.err);
        }

        // templates = templates.data;

        // let chosenTemplate = find(templates, { status: 'Live' });
        // if (!chosenTemplate) chosenTemplate = maxBy(templates, 'version');
        // if (!chosenTemplate) chosenTemplate = maxBy(templates, 'createdAt');

        const chosenTemplate =
          find(templates, { status: 'Live' }) ||
          maxBy(templates, 'version') ||
          maxBy(templates, 'createdAt');

        let templateCommitFile = await makeRequest(
          getItems.templateCommitFile(
            chosenTemplate,
            template.id,
            $evl.toolChosen.id,
            true
          )
        );

        if (templateCommitFile.err) {
          evlUtils.cancelEvaluation();

          $store.commit('SET_IS_LOADING', false);
          $store.commit('SET_PROHIBIT_INPUT', false);

          errorHandler(templateCommitFile.err);
        }

        templateCommitFile = templateCommitFile.data;

        template = templateCommitFile;
        $evl.reportTemplate = template;
        $evl.reportTemplateId = template.id;
      } else {
        $evl.reportTemplate = {};
        $evl.reportTemplateId = null;
      }

      // endregion report template
      $reassessmentOption = !this.isReassessment
        ? null
        : _me.institution
        ? _me.institution.reassessment
        : await $modals.evaluation.chooseReassessmentOptionType();

      const assignEvalData = (tool) => {
        if (!$evl.evaluation) {
          return;
        }

        const EVAL_DATA = $evl.evaluation.data;

        // carry over tool notes and general notes if they exist
        if ($reassessmentEvl && $reassessmentOption == 'AUTO_ANSWER') {
          if ($reassessmentEvl.evaluationData?.toolNotes)
            $evl.evaluation.toolNotes =
              $reassessmentEvl.evaluationData.toolNotes;
          if ($reassessmentEvl.evaluationData?.generalNotes)
            $evl.evaluation.generalNotes =
              $reassessmentEvl.evaluationData.generalNotes;
          if ($reassessmentEvl.evaluationData?.toolSourcesOfInformation)
            $evl.evaluation.toolSourcesOfInformation =
              $reassessmentEvl.evaluationData.toolSourcesOfInformation;
        }

        for (const item of tool.codingFormItems) {
          const qid = item.longAddress || item.id;
          const evalDataItem = EVAL_DATA[qid];

          if ($reassessmentEvl && $reassessmentOption == 'AUTO_ANSWER') {
            EVAL_DATA[qid] = $reassessmentEvl.evaluationData.data[qid];
            continue;
          }

          if (!evalDataItem) {
            EVAL_DATA[qid] = { aid: '-', qid, score: '-', text: '-' };
            continue;
          }

          if (evalDataItem.aid && evalDataItem.aid !== '-') {
            continue;
          }

          evalDataItem.qid = qid;
          evalDataItem.aid = '-';
          evalDataItem.score = '-';
          evalDataItem.text = '-';
        }

        if (tool.childTools?.length) {
          tool.childTools.forEach(assignEvalData);
        }
      };

      if (!$newEvl && !$evl.evaluation && !referrer && !toolOnly) {
        $evl.evaluation = {
          evaluationId: $evl.evaluationId,
          clientId: $evl.client.id,
          clientName: $evl.client.name(),
          evaluatorId: _me.id,
          evaluatorName: `${_me.fName} ${_me.lName}`,
          institutionId: $evl.client.institutionId,
          institutionName: $evl.client.institution.name,
          toolUsed: $evl.toolChosen.id,
          toolCommitId: $evl.toolChosen.publishedCommitId,
          toolName: $evl.reportTemplate.name,
          toolVersion: $evl.reportTemplate.version,
          generalNotes: '',
          interview: {
            interviewer: _me.id,
            dateTime: dateTimeNow()
          },
          data: {},
          elapsedTime: $evl.elapsedTime
        };
      }

      if ($newEvl && !referrer && !toolOnly) {
        // If it's a new evaluation, POST it so we can get an id
        // $evl.evaluation = {
        //   evaluationId: '',
        //   clientId: $evl.client.id,
        //   clientName: $evl.client.name(),
        //   evaluatorId: _me.id,
        //   evaluatorName: `${_me.lName}, ${_me.fName}`,
        //   institutionId: $evl.client.institutionId,
        //   institutionName: $evl.client.institution.name,
        //   toolUsed: $evl.toolChosen.id,
        //   toolCommitId: $evl.toolChosen.publishedCommitId
        //     ? $evl.toolChosen.publishedCommitId
        //     : $evl.toolChosen.toolCommitId, // key updated for backend
        //   toolName: $evl.reportTemplate.name,
        //   toolVersion: $evl.reportTemplate.version,
        //   generalNotes: '',
        //   interview: {
        //     interviewer: _me.id,
        //     dateTime: dateTimeNow()
        //   },
        //   data: {},
        //   elapsedTime: 0
        // };

        // set up eval.data default answers to "-" so it can be initialized
        assignEvalData($evl.tool);

        let saveEvalResponse = null;
        let error = null;

        try {
          saveEvalResponse = await $api2.cm.createClientEvaluation({
            institutionId: this.client.institutionId,
            subGroupId: this.client?.subGroup.id,
            clientId: this.client.id,
            evaluatorId: _me.id,
            toolId: $evl.toolChosen.id,
            toolCommitId: $evl.evaluation.toolCommitId,
            offenderHistoryId: $evl.offenderHistory?.id
              ? $evl.offenderHistory.id
              : $evl.offenderHistoryId
          });
        } catch (err) {
          error = err;
        }

        if (error) {
          evlUtils.cancelEvaluation();
          //this.loadingTool = false;
          $store.commit('SET_IS_LOADING', false);
          $store.commit('SET_PROHIBIT_INPUT', false);

          return notify.display(error, 'error');
        } else {
          const newEvlId = saveEvalResponse.id;

          const newEvl = await $api.CM.getClientEvaluation({
            instId: this.client.institutionId,
            sbGrpId: this.client?.subGroup.id,
            clntId: this.client.id,
            evalId: newEvlId
          });

          $evl.evaluation.evaluationId = newEvlId;
          $evl.evaluation.id = newEvlId;

          addEvaluation($reincode.fullObject(newEvl.data));
        }
      }

      // Assign answer id's if need be.
      if (!toolOnly) {
        // check for clientAge
        if (!$evl.evaluation.clientAge) {
          $evl.evaluation.clientAge = this.client.age
            ? this.client.age
            : utils.dateTime.calcAge(this.client.dob);
        }
        //  check for zoneName
        if (!$evl.evaluation.clientZoneName) {
          $evl.evaluation.clientZoneName = this.client.zone
            ? this.client.zone.name
            : this.client.zoneName;
        }
        //  check for regionName
        if (!$evl.evaluation.clientRegionName) {
          $evl.evaluation.clientRegionName = this.client.region
            ? this.client.region.name
            : this.client.regionName;
        }
        //  check for subGroupName
        if (!$evl.evaluation.clientSubGroupName) {
          $evl.evaluation.clientSubGroupName = this.client.subGroup
            ? this.client.subGroup.name
            : this.client.subGroupName;
        }

        assignEvalData($evl.tool);
      }

      if ($reassessmentEvl && $evl) {
        // $evl.evaluation.reassessmentData = $reassessmentEvl.evaluationData.data;

        $evl.evaluation.reassessmentData = {};

        for (const key in $reassessmentEvl.evaluationData.data) {
          $evl.evaluation.reassessmentData[key] = {
            ...$reassessmentEvl.evaluationData.data[key]
          };
        }
      }

      if ($reassessmentEvl) {
        // we're done using reassessmentEvl and need to empty it
        $reassessmentEvl = null;
      }

      if (!toolOnly && 'interview' in $evl.evaluation === false) {
        $evl.evaluation.interview = {
          interviewer: _me.id,
          dateTime: format(new Date(), 'MM/dd/yyyy')
        };
      } else if (!toolOnly) {
        $evl.evaluation.interview.dateTime = format(
          new Date($evl.evaluation.interview.dateTime),
          'MM/dd/yyyy'
        );
      }

      //check for autoanswers on tools
      const checkAutoAnswer = (tool) => {
        if (!tool.autoAnswer?.length) {
          return (tool.childTools || []).forEach(checkAutoAnswer);
        }

        // let aaArray = [];
        let conditionMet = true;

        const findQuestion = function (arr) {
          let t = $evl.tool;
          let question;
          forEach(arr, (i) => {
            if (i.includes('Q-')) {
              // is question
              question = find(t.codingFormItems, { id: i });
            } else if (typeof parseInt(i, 10) === 'number') {
              // is tool
              forEach(t.childTools, (ct) => {
                if (ct.id === parseInt(i, 10)) t = ct;
              });
            }
          });
          return question;
        };

        const checkAA = function (aaArray, message) {
          // auto answer here
          const question = findQuestion(aaArray);
          if (!question) {
            console.error('AutoAnswer:Could not find question');
            return;
          }
          const answerId = find(aaArray, (i) => {
            return i.includes('A-');
          });
          if (!answerId) {
            console.error('AutoAnswer:Answer ID could not be found');
            return;
          }
          const answer = find(question.codesAndScore, (cas) => {
            return cas.id === answerId;
          });
          if (!answer) {
            console.error(
              `AutoAnswer:Answer could not be found in Question's Codes and Scores list with Answer ID: ${answerId}`
            );
            return;
          }
          // If we already have the answer, just skip
          if ($evl.evaluation.data[question.longAddress].aid === answer.id) {
            return;
          }

          $evl.evaluation.data[question.longAddress].aid = answer.id;
          $evl.evaluation.data[question.longAddress].text = answer.text;
          $evl.evaluation.data[question.longAddress].score = answer.score;
          // Don't display auto answers done when starting evaluation
          // if (message?.length) {
          //   message = message.replace(/</g, '{').replace(/>/g, '}');
          //   notify.display(
          //     message,
          //     'success',
          //     8000,
          //     'Scoring Rule Notice',
          //     'center'
          //   );
          // }
        };

        forEach(tool.autoAnswer, (aa) => {
          conditionMet = true;
          conditionMet = t.checkConditions(aa.conditions, aa.conditionModifier);
          if (conditionMet) {
            if (Array.isArray(aa.answer)) {
              forEach(aa.answer, (a) => {
                checkAA(a.split('>'), !aa.noMessage ? aa.message : null);
              });
            } else if (aa.answer) {
              checkAA(aa.answer.split('>'), !aa.noMessage ? aa.message : null);
            }
          }
        });

        if (tool.childTools?.length) {
          tool.childTools.forEach(checkAutoAnswer);
        }
      };

      const is = (target, tests = []) => {
        return tests.some((o) => o === target);
      };

      // Additional information needed for an evaluation
      if (
        !toolOnly &&
        is($evl.tool.id, [120, 156]) &&
        $evl.client.clientNormativeType
      ) {
        // YLS/CMI, Hare PCL-R, and LSI-R
        $evl.evaluation.additionalInformation = {
          clientNormativeType: $evl.client.clientNormativeType
        };
      } else if (
        !toolOnly &&
        $evl.tool.id === 124 &&
        $evl.client.occupationalStanding &&
        $evl.client.setting
      ) {
        //LS/CMI
        $evl.evaluation.additionalInformation = {
          occupationalStanding: $evl.client.occupationalStanding,
          setting: $evl.client.setting
        };
      } else if (!toolOnly && $evl.tool.id === 157 && $evl.client.setting) {
        //LSI-R
        $evl.evaluation.additionalInformation = {
          setting: $evl.client.setting
        };
      }

      // set up offender history id on data
      if ($evl.offenderHistory && !$evl.evaluation.offenderHistoryId)
        $evl.evaluation.offenderHistoryId = $evl.offenderHistory.id
          ? `${$evl.offenderHistory.id}`
          : $evl.offenderHistory.id;

      if (!toolOnly) {
        checkAutoAnswer($evl.tool);
        this.calculateEvaluationScoreTotal();
      }

      $store.commit('tools/SET_FOCUS', $evl.tool.id);
      if (toolOnly) this.tool = $evl.tool;

      // Set retrieved tool data to list of _tools so we don't
      // have to request again if we don't have to.
      const tool = find(_tools, { id: $evl.tool.id });
      if (tool) {
        tool.toolData = $evl.tool;
      }

      $store.commit('SET_IS_LOADING', false);
      $store.commit('SET_PROHIBIT_INPUT', false);

      if (referrer) {
        // Loading tool for something else
        console.log('referrer: ', referrer);
        return $state.go(referrer);
      }

      if (toolOnly) {
        return;
      }

      // loading tool for an evaluation
      this.calculateEvaluationScoreTotal();

      // this.changeSection('perform-evaluation');
      reportUtils.evlForReport = $evl.evaluation;

      if ($state.current.name !== 'dashboardEvaluationsPerform') {
        $state.go('dashboardEvaluationsPerform');
      }

      const evalRequest = find(myEvaluationRequests(), (evlReq) => {
        return evlReq.evaluationId === $evl.evaluationId;
      });

      if (evalRequest) {
        evalRequest.status = 'IN_PROGRESS';
        $store.commit('me/UPDATE_EVL_REQ', evalRequest);
      }

      if (!$newEvl || $evl.evaluation.backup) this.manuallySaveEvaluation();

      this.startSavingEvaluation();
    },
    /**
     * ...
     *
     * @param conditions ...
     * @param conditionModifier ...
     */
    checkConditions(
      conditions: AutoAnswer.Condition[],
      conditionModifier?: string | null
    ) {
      $evl = asserters.assessment();

      return checkConditions($evl, conditions, conditionModifier ?? null);

      // let t = this;
      //
      // let atLeast: string | null = null;
      // let lessThan: string | null = null;
      //
      // let modifiers: string[] | null = null;
      //
      // if (conditionModifier) {
      //   modifiers = conditionModifier.split(':');
      //
      //   switch (modifiers[0]) {
      //     case 'atLeast':
      //       atLeast = modifiers[1];
      //       break;
      //     case 'lessThan':
      //       lessThan = modifiers[1];
      //   }
      // }
      //
      // // checks auto answer or warning check conditions
      // let conditionMet = true;
      // let variable: unknown | null = null;
      // let operator: unknown | null = null;
      // let comparator: unknown | null = null;
      // let oHField: unknown | null = null;
      // let  evalField: unknown | null = null;
      //
      // // helper function to finally evaluate condition
      // const evalCond = (left: string | number, operator: string, right: string[]) => {
      //   let passed = false;
      //
      //   forEach(right, (r) => {
      //     const value = typeof left === 'number' && typeof r !== 'number' ? parseInt(r) : r;
      //
      //     switch (operator) {
      //       case '=':
      //
      //     }
      //
      //     // switch (operator) {
      //     //   case '=':
      //     //   return left === value
      //     //   case '>':
      //     //   return left > value
      //     //   case '<':
      //     //   return left < value
      //     //   case '<=':
      //     //   return left <= value
      //     //   case '>=':
      //     //   return left >= value
      //     //   case '!=':
      //     //   return left !== value
      //     //   default:
      //     //   return false
      //     // }
      //
      //
      //   });
      //
      //   return passed;
      // };
      //
      // // helper function to find offender history field
      // let findOHField = function (variable) {
      //   let section = $evl.offenderHistory;
      //   let field;
      //   forEach(variable, (key) => {
      //     if (key === 'offenderHistory') return;
      //     if (find(section.sections, { key })) {
      //       section = find(section.sections, { key });
      //     } else if (find(section.fields, { key })) {
      //       field = find(section.fields, { key });
      //     }
      //   });
      //   return field;
      // };
      //
      // // helper function to find evaluation field
      // let findEvalField = function (variable) {
      //   //evaluation.data.125>133>Q-TvhtTH6DS.aid
      //   let value = $evl;
      //   forEach(variable, (key) => {
      //     if (value[key]) value = value[key];
      //   });
      //   return value;
      // };
      //
      // let conditionMetCounter = 0;
      //
      // // helper function to evaluation a single condition object
      // let evaluateSingleCondition = function (cond: Condition) {
      //   variable = cond.variable.split('.');
      //   if (!variable?.length) {
      //     console.error('Variable must be an array');
      //     return;
      //   }
      //   operator = cond.operator;
      //   comparator = cond.comparator.split('||');
      //
      //   switch (variable[0]) {
      //     case 'client':
      //       if (!evalCond($evl.client[variable[1]], operator, comparator)) {
      //         // conditionMet = false;
      //         return false;
      //       } else {
      //         conditionMetCounter++;
      //         return true;
      //       }
      //     case 'offenderHistory':
      //       oHField = null;
      //       oHField = findOHField(variable);
      //       if (oHField) {
      //         if (!evalCond(oHField.model, operator, comparator)) {
      //           // conditionMet = false;
      //           return false;
      //         } else {
      //           conditionMetCounter++;
      //           return true;
      //         }
      //       } else {
      //         console.error(
      //           `Could not find offender history field for auto answer condition check: ${variable}`
      //         );
      //         // conditionMet = false;
      //         return false;
      //       }
      //     case 'evaluation':
      //       evalField = null;
      //       evalField = findEvalField(variable);
      //       if (evalField) {
      //         if (!evalCond(evalField, operator, comparator)) {
      //           // conditionMet = false;
      //           return false;
      //         } else {
      //           conditionMetCounter++;
      //           return true;
      //         }
      //       } else {
      //         console.error(
      //           `Could not find evaluation field for auto answer condition check: ${variable}`
      //         );
      //         // conditionMet = false;
      //         return false;
      //       }
      //     default:
      //       return true;
      //   }
      // };
      //
      // // helper function to take in array of conditions and evaluate as a whole
      // let evaluateConditions = function (conditions: Condition[], conditionOperator?: string | null) {
      //   let checksArray = [];
      //   forEach(conditions, (cond) => {
      //     if ('conditions' in cond) {
      //       if ('conditionModifier' in cond) {
      //         let ccRes = t.checkConditions(
      //           cond.conditions,
      //           cond.conditionModifier
      //         );
      //         if (!ccRes) conditionMet = false;
      //       } else {
      //         // we have a subset of conditions that need to be checked
      //         evaluateConditions(cond.conditions, cond.operator);
      //       }
      //     } else {
      //       let evalRes = evaluateSingleCondition(cond);
      //       if (!conditionOperator && !evalRes) {
      //         conditionMet = false;
      //       } else {
      //         checksArray.push(evalRes);
      //       }
      //     }
      //   });
      //   if (conditionOperator) {
      //     // we have multiple conditions to keep track of and evaluate before making
      //     // a decision if the condition was met
      //     switch (conditionOperator) {
      //       case '||':
      //         if (!checksArray.includes(true)) conditionMet = false;
      //         break;
      //       case '&&':
      //         if (checksArray.includes(false)) conditionMet = false;
      //         break;
      //     }
      //   }
      // };
      //
      // evaluateConditions(conditions);
      //
      // if (!conditionModifier) {
      //   return conditionMet;
      // } else if (atLeast) {
      //   return conditionMetCounter >= atLeast;
      // } else if (lessThan) {
      //   return conditionMetCounter < lessThan;
      // }
    },
    /**
     * ...
     */
    resetAnswer(cfi: unknown) {
      if (!$evl?.evaluation?.data) return;
      const evalData = $evl.evaluation.data;
      const dataId = cfi.longAddress ? cfi.longAddress : cfi.id;
      if (cfi.longAddress && evalData[dataId] && evalData[dataId].aid !== '-') {
        evalData[dataId].aid = '-';
        evalData[dataId].score = '-';
        evalData[dataId].text = '-';
        this.updateEvaluationQuestionData(dataId, cfi.codesAndScore);
      }
    },
    /**
     * ...
     */
    makeToolTable(t: unknown) {
      const table = {
        name: t.name,
        evalScoreVariable: t.evalScoreVariable,
        inputs: t.getScore?.inputs,
        tableValues: t.getScore?.tableValues,
        formulas: t.getScore?.formulas
      };
      return table;
    },
    /**
     * ...
     */
    parseToolScoreTable(table: unknown) {
      // gather input values from table.inputs
      const inputs = [];
      const inputsObj = {};
      const getInputValue = function (varStr) {
        // should be a value we can ascertain from $evl
        const inputSplit = varStr.split('.');
        let fVal = $evl;
        // need to track if we're parsing prorates or dynamic scores so we can
        // get the correct total score vs prorated total score
        let prorateTable = false;
        forEach(inputSplit, (is) => {
          if (is === 'tableProrates') prorateTable = true;
          if (!fVal) return;
          if (is === 'totalScore' && !prorateTable) {
            fVal = $evlScoreTotal;
          } else {
            fVal = fVal[is];
          }
        });
        if (!fVal && fVal !== 0)
          console.error(
            `parseToolScoreTable could not find input split final value: ${varStr}`
          );
        inputsObj[varStr] = fVal;
        return fVal;
      };
      forEach(table.inputs, (input) => {
        inputs.push(getInputValue(input));
      });
      // check if we have a formula available
      const hasAvailableFormula = function () {
        if (!table.formulas) return;
        let hasKey = false;
        forEach(table.formulas, (val, key) => {
          forEach(inputsObj, (v) => {
            if (v === key) hasKey = key;
          });
        });
        return hasKey;
      };
      const formulaKey = hasAvailableFormula();
      if (formulaKey) {
        let score;
        let formula = table.formulas[formulaKey];
        const variableSubStr = formula.substring(
          formula.lastIndexOf('{') + 1,
          formula.lastIndexOf('}')
        );
        formula = formula.replace(variableSubStr, inputsObj[variableSubStr]);
        formula = formula.replace('{', '');
        formula = formula.replace('}', '');
        score = eval(formula);
        if (!score && score !== 0)
          console.error(
            `parseToolScoreFromula could not find final score value: ${table.name}`
          );
        if (parseFloat(score) === 0 || parseFloat(score)) {
          score = parseFloat(score);
          score = Math.round(score);
        }
        return score;
      } else {
        // assess if table.tableValues has a final value chain to return for score
        let score = table.tableValues;
        forEach(inputs, (is) => {
          if (!score) return;
          // round "is" up if it's a float (Hare PCL-R example)
          if (parseFloat(is) === 0 || parseFloat(is)) {
            is = parseFloat(is);
            is = Math.round(is);
          }
          score = score[is];
        });
        if (!score && score !== 0)
          console.error(
            `parseToolScoreTable could not find final score value: ${table.name}`
          );
        return score;
      }
    }
  };

  // If we have customRiskCategories, find the matching criteria and set to the
  // tool's riskCategories array.
  function checkCustomRiskCategories(list: unknown) {
    forEach(list, (crc) => {
      if (!crc.criteria) return;

      let criteriaMatch = true;

      for (const key in crc.criteria) {
        const val = crc.criteria[key];

        if (
          (key == 'sex' && val !== $evl.client.sex) ||
          (key == 'clientNormativeType' &&
            val !== $evl.client.clientNormativeType)
        ) {
          criteriaMatch = false;
        }
      }

      crc.criteriaMatch = criteriaMatch;
    });

    return list;
  }

  function buildBarGraphs() {
    // Create bar graphs and functions here.
    if (!$evl.tool.childTools?.length) return;

    for (const ct of $evl.tool.childTools) {
      if (!ct?.childTools?.length) {
        continue;
      }

      // create barGraph components here
      // check if all riskCategories are the same for childTools
      const masterRiskCategories = [];
      let masterRiskCategoriesOriginal = [];
      let currentRiskCategories = [];
      let allRiskCategoriesSame = true;

      for (const i in ct.childTools) {
        const t = ct.childTools[i];
        currentRiskCategories = [];
        forEach(t.riskCategories, (rc) => {
          if (i == 0) {
            masterRiskCategories.push(rc.name);
            masterRiskCategoriesOriginal = [...masterRiskCategories];
            currentRiskCategories = masterRiskCategories;
          } else {
            currentRiskCategories.push(rc.name);
          }
        });

        if (
          !isEqual(currentRiskCategories.sort(), masterRiskCategories.sort())
        ) {
          allRiskCategoriesSame = false;
        }
      }

      let maxRiskCategoryLength = 0;
      const chartLabels = [];
      const chartData = [];
      const colors = [];

      for (const { riskCategories, flyoutName, name } of ct.childTools) {
        if (riskCategories.length > maxRiskCategoryLength) {
          maxRiskCategoryLength = riskCategories.length;
        }

        // X-axis labels for the bar graph
        chartLabels.push(flyoutName || name);
        // Push data to get the bars initialized
        chartData.push(0);
        // Force bar color
        colors.push('#9b373f');
      }

      ct.bar = {
        data: chartData,
        labels: chartLabels,
        colors,
        series: [],
        options: {
          gridLines: {
            display: false
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: 'Sub Component',
                  fontColor: 'black'
                },
                ticks: {
                  display: true,
                  fontColor: 'black'
                }
              }
            ],
            yAxes: [
              {
                ticks: {
                  suggestedMax: maxRiskCategoryLength,
                  beginAtZero: true,
                  stepSize: 1,
                  callback(label) {
                    if (!allRiskCategoriesSame) {
                      return label;
                    }

                    switch (label) {
                      case 0:
                        return ' ';
                      default:
                        return masterRiskCategoriesOriginal[label - 1];
                    }
                  },
                  display: true,
                  fontColor: 'black'
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Risk/Need Level',
                  fontColor: 'black'
                }
              }
            ]
          },
          toolTipEvents: [],
          showTooltips: true,
          tooltipCaretSize: 0,
          onAnimationComplete() {
            this.showTooltip(this.segments, true);
          },
          tooltips: {
            callbacks: {
              label: function (toolTipItem) {
                return null;
              }
            }
          }
        },
        colours: {
          fontColor: 'black'
        }
      };
    }
  }

  /**
   * ...
   */
  const onSaveInterval = async () => {
    evlUtils.autoSaving = true;

    const url = $location.url();

    if (url && !url.includes('/perform-evaluation')) {
      return evlUtils.cancelInterval();
    }

    $evl.evaluation.evaluatorId = _me.id;

    if (typeof $evl.evaluation.elapsedTime !== 'undefined') {
      // Calculate time diff from previous time and add to evaluation payload
      const currentTime = new Date();
      let timeDiff =
        (currentTime.getTime() - evlUtils.evalTimeFirst.getTime()) / 1000;

      timeDiff = Math.floor(timeDiff);
      evlUtils.evalTimeFirst = currentTime;

      $evl.evaluation.elapsedTime += timeDiff;
    }

    const evalData = $reincode.fullObject(angular.copy($evl.evaluation), true);

    // make sure we're tracking offender history id
    if ($evl?.offenderHistoryId && !evalData.offenderHistoryId)
      evalData.offenderHistoryId = $evl.offenderHistoryId
        ? `${$evl.offenderHistoryId}`
        : $evl.offenderHistoryId;

    // check if evalData is different, if not, no need to submit it
    let evalSaveRes;
    if (
      !this.previouslySavedEvalData ||
      !isEqual(
        omit(this.previouslySavedEvalData, ['elapsedTime']),
        omit(evalData, ['elapsedTime'])
      )
    ) {
      try {
        evalSaveRes = await $api2.cm.saveClientEvaluation({
          institutionId: evlUtils.client.institutionId,
          subGroupId: evlUtils.client?.subGroup.id,
          clientId: evlUtils.client.id,
          evaluationId: $evl.evaluation.evaluationId,
          data: evalData
        });

        this.previouslySavedEvalData = evalData;

        $evl.status = 'IN_PROGRESS';
      } catch (err) {
        console.error(err);
      }
    }

    evlUtils.autoSaving = false;

    if (evalSaveRes) $evl.evaluation.backup = evalSaveRes.backup;
  };

  // NOTE: For helping debugging. Comment out when not needed.
  // addMethodLoggers(evlUtils);

  return evlUtils;
}

/**
 * Helper function for handling requests.
 *
 * @param req ...
 * @return ...
 */
async function makeRequest<T = unknown>(req: Promise<T>) {
  let data: T | null = null;
  let error: Error | null = null;

  try {
    data = await req;
  } catch (err) {
    error = err;
  }

  return { data, err: error };
}

/**
 * ...
 *
 * @param date ...
 * @return ...
 */
function dateTimeNow(date?: string | number | Date) {
  const now = date ? new Date(date) : new Date();

  return new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    now.getHours(),
    now.getMinutes()
  );
}

/**
 * ...
 *
 * @param userId ...
 * @return ...
 */
function getUserAgreements(userId: string) {
  if (!window.Storage) return [];

  let data: unknown = null;

  try {
    data = JSON.parse(localStorage.getItem(`${userId}_gta`) ?? '[]');
  } catch (_) {
    data = [];
  }

  data = Array.isArray(data) ? data : [];

  return data.filter(
    (item) => typeof item === 'string' || typeof item === 'number'
  ) as (string | number)[];
}

/**
 * ...
 *
 * @param userId ...
 * @param toolId ...
 */
function addUserAgreement(userId: string, toolId: number) {
  if (!window.Storage) return;

  const agreements = getUserAgreements(userId);

  localStorage.setItem(
    `${userId}_gta`,
    JSON.stringify(agreements.concat(toolId))
  );
}

/**
 * FOR DEV: Add logs to the beginning of each method to help identify when
 * and the order in which they are called.
 *
 * @param evlUtils Reference to the eval-utils service instance.
 */
function addMethodLoggers(evlUtils: GenericObject) {
  for (const key in evlUtils) {
    const { value } = Object.getOwnPropertyDescriptor(evlUtils, key);

    if (typeof value !== 'function') continue;

    const method = function (...args: unknown[]) {
      console.log(`eval utils method: ${key}`);

      return value.bind(evlUtils)(...args);
    };

    evlUtils[key] = method.bind(evlUtils);
  }
}
