import angular from 'angular';
import { v4 as uid } from 'uuid';

import { Controller, Inject } from '@decorators/ngCtrl';
import { DatabaseModel, Pagination } from '@interfaces';

//#region Public Table Types

export interface TableProperty<T = unknown> {
  label: string;
  value: string | ((row: T) => unknown);
  filter?: TableProperty.FilterConfig;
  isDate?: unknown;
  hide?: () => boolean;
}

export namespace TableProperty {
  export interface FilterConfig {
    type: 'date';
    format: string;
  }
}

export interface GlobalTableAction {
  label: string;
  fn: () => unknown;
  disabled?: () => boolean;
  hide?: () => boolean;
}

export interface TableAction<T = unknown> {
  label: string;
  icon: string;
  actions: TableActionItem<T>[] | (() => TableActionItem<T>[]);
  disabled?: boolean | ((row: T) => boolean);
  hide?: () => boolean;
}

export interface TableActionItem<T = unknown> {
  label: string;
  icon: string;
  fn: (row: T) => unknown;
  disabled?: (row: T) => boolean;
  hide?: () => boolean;
}

export interface LoadPageContext {
  queryText?: string;
  queryParams?: Record<string, unknown>;
  startKey?: Pagination.PlacementKey;
  minLimit?: number;
}

export type LoaderFunction<T extends DatabaseModel> = (
  ctx: LoadPageContext
) => Promise<Pagination.Results<T>>;

//#endregion Public Table Types

//#region Internal Table Types

type RowData = Record<string, unknown>;

interface TableRow {
  key: string | number;
  ref: DatabaseModel;
  data: RowData;
}

type TableColumn = Pick<
  TableProperty,
  'label' | 'value' | 'filter' | 'isDate'
> & { key: string; index: number };

interface UtilityColumn<T = unknown> {
  key: string;
  index: number;
  label: string;
  icon: string;
  actions: TableActionItem<T>[] | (() => TableActionItem<T>[]);
  disabled: (row: T) => boolean;
}

interface UtilityTableAction {
  label: string;
  fn: () => unknown;
  disabled: () => boolean;
}

interface ActiveFlyoutSelector {
  row: string | null;
  col: string | null;
}

//#endregion Internal Table Types

@Controller
class DisplayTableProgressiveComponent<T extends DatabaseModel> {
  //#region Props

  props!: TableProperty[];
  actions!: TableAction[];
  queryText: string | null = null;
  queryParams: Record<string, unknown> | null = null;
  loader!: LoaderFunction<T>;
  itemTitle!: string;
  showIdInActions = false;
  selectableRows = false;
  tableActions!: GlobalTableAction[];

  //#endregion Props

  items: T[] = [];
  rows: TableRow[] = [];
  cols: TableColumn[] = [];
  utilCols: UtilityColumn[] = [];
  utilTableActions: UtilityTableAction[] = [];
  activeFlyout: ActiveFlyoutSelector = { row: null, col: null };

  propListeners: (() => void)[] = [];
  itemListeners: (() => void)[] = [];

  refreshRows = false;
  prevQueryText: string | null = null;
  queryTimeout: angular.IPromise<void> | null = null;
  actionCols: Record<string, number | null> = {};
  actionColWidth = 0;

  currentQueryId: string | null = null;
  loading = false;
  loadProgress = 0;
  loadDeferred: angular.IDeferred<unknown> | null = null;

  lastEvaluatedKey: Pagination.PlacementKey | null = null;
  allResultsLoaded = false;

  @Inject readonly $scope!: angular.IScope;
  @Inject readonly $element!: angular.IElement;
  @Inject readonly $rootScope!: angular.IRootScopeService;
  @Inject readonly $filter!: angular.IFilterService;
  @Inject readonly $timeout!: angular.ITimeoutService;
  @Inject readonly $q!: angular.IQService;
  @Inject readonly $store!: angular.gears.IStoreService;
  @Inject readonly $delay!: angular.gears.IDelayService;
  @Inject readonly $notify!: angular.gears.INotifyService;
  @Inject readonly utils!: angular.gears.IUtilService;

  get flyoutActive() {
    return !!this.activeFlyout.row && !!this.activeFlyout.col;
  }

  get tableWrapperElem() {
    return ((this.$scope as any)['tableWrapper'] ??
      null) as angular.IElement | null;
  }

  get progressBarElem() {
    return ((this.$scope as any)['progressBar'] ??
      null) as angular.IElement | null;
  }

  get displayResultCount() {
    const count = this.rows.length.toString();

    let value;

    if (this.allResultsLoaded) {
      value = `${count} / ${count}`;
    } else {
      value = count;
    }

    return value;
  }

  get loadingPage() {
    return this.currentQueryId !== null;
  }

  get tableWrapperHeight() {
    /**
     * Do our best to calculate the appropriate height for the table.
     * If we can't default to 800px
     */
    let height = 800;

    let occupiedSpace = 0;

    // assuming navbar is always present
    const navBar = document.getElementsByClassName('site-header');

    if (navBar.length) {
      for (let index = 0; index < navBar.length; index++) {
        occupiedSpace += navBar[index].clientHeight;
      }
    }

    // assuming the table resides on a dashboard page with sec-nav containers
    const toolBars = document.getElementsByClassName('sect-nav');

    if (toolBars.length) {
      for (let index = 0; index < toolBars.length; index++) {
        occupiedSpace += toolBars[index].clientHeight;
      }
    }

    const appBase = document.getElementById('app-base');
    const windowHeight = appBase?.offsetHeight;

    if (
      occupiedSpace &&
      windowHeight &&
      typeof occupiedSpace === 'number' &&
      typeof windowHeight === 'number'
    )
      height = windowHeight - occupiedSpace;

    return height;
  }

  $onInit() {
    this.$watchSet('props', this.createCols);
    this.$watchSet('actions', this.createActions);
    this.$watchSet('tableActions', this.createTableActions);
    this.$watchSet('items', this.createRows);

    this.$scope.$on('refreshTable', () => this.createRows());

    this.$scope.$watch('refreshRows', (val) => {
      if (val) this.createRows();
    });

    this.prevQueryText = this.queryText;

    this.$scope.$watch(
      () => this.queryText,
      (newValue, oldValue) => {
        if (newValue === oldValue) return;

        if (this.queryTimeout) {
          this.$timeout.cancel(this.queryTimeout);
        }

        if (this.prevQueryText === newValue) return;

        this.queryTimeout = this.$timeout(() => {
          this.prevQueryText = newValue;

          this.loadItems(true);
        }, 500);
      }
    );

    this.$scope.$watchCollection(
      () => this.queryParams,
      (newValue, oldValue) => {
        if (newValue !== oldValue) this.loadItems(true);
      }
    );

    this.$scope.$watch(
      () => this.loader,
      (newValue, oldValue) => {
        if (newValue !== oldValue) this.loadItems(true);
      }
    );

    this.$scope.$watchCollection(
      () => this.actionCols,
      () => {
        const widths = Object.values(this.actionCols);

        if (widths.length !== this.utilCols.length) return;

        this.actionColWidth = 0;

        for (const width of widths) {
          if (typeof width === 'number' && width > this.actionColWidth) {
            this.actionColWidth = width;
          }
        }
      }
    );
  }

  $postLink() {
    this.tableWrapperElem?.on('scroll', this.onScroll);
    this.progressBarElem?.on('transitionend', this.onAnimationEnd);

    void this.loadItems();
  }

  //#region Public Methods

  /**
   * Loads the next set of table items (if any are remaining)
   */
  loadNext() {
    if (this.lastEvaluatedKey) {
      void this.loadItems();
    }
  }

  /**
   * Clears all entries from the table and loads a new set from the beginning
   */
  reset() {
    void this.loadItems(true);
  }

  /**
   * Sets a table flyout popup to be active for a given row and column (cell).
   */
  setFlyout(row: string, col: string) {
    if (this.activeFlyout.row !== row || this.activeFlyout.col !== col) {
      this.activeFlyout = { row, col };
    } else {
      this.unsetFlyout(row, col);
    }
  }

  /**
   * Unsets an active flyout popup for the given row and column (cell).
   */
  unsetFlyout(row: string, col: string) {
    if (this.activeFlyout.row === row && this.activeFlyout.col === col) {
      this.activeFlyout = { row: null, col: null };
    }
  }

  //#endregion Public Methods

  private $watchSet(prop: keyof this, cb: (...args: unknown[]) => unknown) {
    return this.$scope.$watchCollection(() => this[prop], cb.bind(this));
  }

  private createRows() {
    this.itemListeners = this.itemListeners.filter((dispose) => {
      dispose();

      return false;
    });

    if (!Array.isArray(this.items)) {
      invalidTablePropError.bind(this)('item rows', this.itemTitle);

      this.rows = [];

      return;
    }

    const rows: TableRow[] = [];

    for (const item of this.items) {
      rows.push(createRow(item, this.cols));

      this.itemListeners.push(
        this.$scope.$watchCollection(
          () => item,
          (newVal, oldVal) => {
            if (newVal !== oldVal) {
              this.refreshRows = true;
            }
          }
        )
      );
    }

    this.rows = rows;

    this.refreshRows = false;
  }

  private createCols() {
    this.propListeners.forEach((dispose) => dispose());

    const cols: TableColumn[] = [];

    if (!Array.isArray(this.props)) {
      if (this.props) {
        invalidTablePropError.bind(this)('item columns', this.itemTitle);
      }

      this.cols = cols;

      return;
    }

    const entries = Object.entries(this.props);

    for (const [index, [key, prop]] of Object.entries(entries)) {
      if (!prop.hide?.()) {
        cols.push({
          key,
          index: +index,
          label: prop.label,
          value: prop.value,
          filter: prop.filter,
          isDate: prop.isDate
        });
      }

      this.$scope.$watchCollection(
        () => prop,
        (newVal, oldVal) => {
          if (newVal !== oldVal) this.createCols();
        }
      );
    }

    this.cols = cols;
  }

  private createActions() {
    const utilCols: UtilityColumn[] = [];

    if (!Array.isArray(this.actions)) {
      if (this.actions) {
        console.error(
          `[data-table] The provided data for the dataTable "${this.itemTitle}" utility columns was not an array.`,
          this.actions
        );
      }

      this.utilCols = utilCols;

      return;
    }

    for (const [index, action] of Object.entries(this.actions)) {
      if (action.hide?.()) continue;

      let actions;

      if (typeof action.actions === 'function') {
        actions = action.actions;
      } else if (Array.isArray(action.actions)) {
        actions = action.actions.filter(
          (subAction) => !subAction.hide || !subAction.hide()
        );

        if (!actions.length) continue;
      } else {
        continue;
      }

      let disabled;

      if (typeof action.disabled === 'boolean') {
        disabled = () => !!action.disabled;
      } else if (typeof action.disabled === 'function') {
        disabled = action.disabled;
      } else {
        disabled = () => false;
      }

      utilCols.push({
        key: index,
        index: +index,
        label: action.label,
        icon: action.icon,
        actions,
        disabled
      });
    }

    this.utilCols = utilCols;
  }

  private createTableActions() {
    const utilTableActions: UtilityTableAction[] = [];

    if (!Array.isArray(this.tableActions)) {
      if (this.tableActions) {
        console.error(
          `[data-table-progressive] The provided data for the dataTable "${this.itemTitle}" table actions was not an array.`,
          this.tableActions
        );
      }

      this.utilTableActions = utilTableActions;

      return;
    }

    for (const tableAction of this.tableActions) {
      if (tableAction.hide?.()) continue;

      let disabled;

      if (typeof tableAction.disabled === 'boolean') {
        disabled = () => !!tableAction.disabled;
      } else if (typeof tableAction.disabled === 'function') {
        disabled = tableAction.disabled;
      } else {
        disabled = () => false;
      }

      utilTableActions.push({
        label: tableAction.label,
        fn: tableAction.fn,
        disabled
      });
    }

    this.utilTableActions = utilTableActions;
  }

  /**
   * Loads the next set of items for the table. If the `clearPrevious` flag is
   * set to `true`, the table will be cleared of all previous items before the
   * next set is loaded.
   *
   * @param clearPrevious Whether to clear all previous items from the table
   * .
   */
  private async loadItems(clearPrevious = false) {
    const queryId = (this.currentQueryId = uid());

    this.loadDeferred = this.$q.defer();

    this.loadProgress = 0;

    await this.$delay(0);

    this.loadProgress = 75;
    this.$scope.$apply();

    const ctx: LoadPageContext = {};

    if (this.queryText) {
      ctx.queryText = this.queryText;
    }

    if (this.queryParams) {
      const entries = Object.entries(this.queryParams).filter(
        ([_, val]) => !!val
      );

      if (entries.length) {
        ctx.queryParams = Object.fromEntries(entries);
      }
    }

    if (clearPrevious || !this.lastEvaluatedKey) {
      this.items = [];
      this.rows = [];
      this.lastEvaluatedKey = null;
      this.allResultsLoaded = false;

      this.$scope.$apply();
    } else {
      ctx.startKey = this.lastEvaluatedKey;
    }

    // If there are no items in the table, set a minimum limit to ensure that
    // the table is scrollable. This is done by dividing the current height
    // of the table wrapper (in pixels) by 60 (the the minimum height of a
    // row in the table), and then multiplying that by 1.5 to ensure that there
    // is a bit of "buffer" space to scroll before the next set of items are
    // loaded.
    if (!this.items.length) {
      const tableWrapperHeight = this.tableWrapperElem?.height() ?? 0;

      ctx.minLimit = Math.ceil((tableWrapperHeight / 60) * 1.5);
    }

    let res: Pagination.Results<T> | Error;

    this.loading = true;

    try {
      res = await this.loader(ctx);
    } catch (err) {
      res = err as Error;
    }

    this.loading = false;

    if (!(res instanceof Error)) {
      this.items = [...this.items, ...res.items];
      this.lastEvaluatedKey = res.lastEvaluatedKey;
      this.allResultsLoaded = !res.lastEvaluatedKey;
    } else {
      /* eslint-disable-next-line no-console */
      console.error(
        '[table-panel:load] failed to fetch table items due to an error: ' +
          res.message
      );
    }

    // If another query has sense been placed, let it handle setting the
    // "query-complete" state.
    if (queryId !== this.currentQueryId) return;

    this.loadProgress = 100;
    this.$scope.$apply();

    await this.loadDeferred.promise;

    this.currentQueryId = null;

    if (res instanceof Error) {
      this.$notify.error(
        `There was an issue while trying to load table items: ${res.message}`
      );
    }

    this.$scope.$apply();
  }

  private onScroll = (event: Event) => {
    if (this.loading || this.allResultsLoaded) return;

    const { scrollHeight, scrollTop, offsetHeight } =
      event.target as HTMLElement;

    const bottom = scrollHeight - offsetHeight;
    const distance = bottom - scrollTop;

    if (distance < 2) void this.loadItems();
  };

  private onAnimationEnd = () => {
    if (this.loadProgress >= 100) this.loadDeferred?.resolve();
  };
}

/**
 * Creates a row object for the given item and columns.
 */
function createRow(item: DatabaseModel, cols: TableColumn[]) {
  const data: RowData = {};

  for (const col of cols) {
    if (typeof col.value === 'function') {
      let value;

      try {
        value = col.value(item);
      } catch {
        value = null;
      }

      data[col.key] = prepareForDisplay(value);

      continue;
    }

    if (typeof col.value !== 'string') {
      throw new Error(
        `[display-table-progressive] Invalid value accessor type: must be either a string or function. passed value type: ${typeof col.value}`
      );
    }

    let output: unknown = item;

    const propPath = col.value.split('.');

    try {
      for (const prop of propPath) {
        output = (output as any)[prop];
      }
    } catch {
      output = null;
    }

    if (Array.isArray(output)) {
      let str = '';

      output.forEach((val, j) => {
        str += `${j > 0 ? '<br/>' : ''}<span>${val.toString()}</span>`;
      });

      output = str;
    }

    data[col.key] = prepareForDisplay(output);
  }

  return {
    key: item.pk + item.sk,
    ref: item,
    data
  } as TableRow;
}

function invalidTablePropError(
  this: DisplayTableProgressiveComponent<any>,
  type: string,
  val: string
) {
  console.error(
    `[data-table] The provided data for the dataTable "${val}" ${type} was not an array.`,
    this.props
  );
}

function prepareForDisplay(val: unknown) {
  return val !== undefined && val !== null && val !== '' ? val : '--';
}

export default angular
  .module('app.displayTableProgressive', [])
  .directive('dtpHeaderTd', () => ({
    require: '^^displayTableProgressive',
    replace: true,
    scope: { col: '=dtpHeaderTd' },
    link(
      scope,
      element,
      _attrs,
      dtCtrl: DisplayTableProgressiveComponent<any>
    ) {
      if (!dtCtrl) return;

      const id = uid();

      dtCtrl.actionCols[id] = null;

      scope.$watch(
        () => element.innerWidth(),
        (w) => {
          dtCtrl.actionCols[id] = w;
        }
      );

      scope.$on('$destroy', () => {
        delete dtCtrl.actionCols[id];
      });
    }
  }))
  .component('displayTableProgressive', {
    template: require('./display-table-progressive.html'),
    bindings: {
      props: '=',
      actions: '=',
      queryText: '=',
      queryParams: '=',
      loader: '=',
      itemTitle: '=',
      showIdInActions: '=',
      selectableRows: '=',
      tableActions: '='
    },
    controller: DisplayTableProgressiveComponent,
    controllerAs: 'vm'
  }).name;
