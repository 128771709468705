import { remove, find, uniqBy } from 'lodash';
import { ActionTree, MutationTree, GetterTree } from 'angular-store';

import { TableState } from '@interfaces';
import { Evaluation } from '@interfaces/evaluation';
import { ResourceMapValue } from '@interfaces/permissions';
import type { Root } from '@store';

export interface EvaluationsState {
  loading: boolean;
  items: Evaluation[];
  focus: Evaluation | null;
  table: TableState;
}

export namespace Getters {
  export type Find = (ref: string | Evaluation) => Evaluation | undefined;
  export type GetById = (id: string) => Evaluation | undefined;
  export type GetByClientId = (clientId: string) => Evaluation[];
}

export namespace Actions {
  export interface List {
    (): Promise<Evaluation[]>;
  }

  export interface Get {
    (): Promise<Evaluation[]>;
  }

  export namespace Get {
    export interface Payload {
      instId: string;
      sbGrpId: string;
      clntId: string;
      evalId: string;
    }
  }

  export interface GetAll {
    (): Promise<Evaluation[]>;
  }

  export interface GetForUser {
    (userId?: GetForUser.Payload): Promise<Evaluation[] | undefined>;
  }

  export namespace GetForUser {
    export type Payload = number;
  }

  export interface GetForClient {
    (clientId: GetForClient.Payload): Promise<Evaluation[] | undefined>;
  }

  export namespace GetForClient {
    export type Payload = string;
  }

  export interface GetForInstitution {
    (institutionId?: GetForInstitution.Payload): Promise<
      Evaluation[] | undefined
    >;
  }

  export namespace GetForInstitution {
    export type Payload = string;
  }

  export interface Submit {
    (userId: Submit.Payload): Promise<Evaluation[]>;
  }

  export namespace Submit {
    export type Payload = string;
  }

  export interface Delete {
    (payload: Delete.Payload): Promise<void>;
  }

  export namespace Delete {
    export interface Payload {
      institutionId?: string;
      clientId: string;
      evaluationId: string;
    }
  }
}

export namespace Mutations {
  export interface Set {
    (payload: Set.Payload): void;
  }

  export namespace Set {
    export type Payload = Evaluation | Evaluation[];
  }

  export interface Add {
    (payload: Add.Payload): void;
  }

  export namespace Add {
    export type Payload = Evaluation | Evaluation[];
  }

  export interface UpdateItem {
    (payload: UpdateItem.Payload): void;
  }

  export namespace UpdateItem {
    export interface Payload {
      ref: string | number | Evaluation;
      props: Partial<Evaluation>;
    }
  }

  export interface SetFocus {
    (evaluationId: SetFocus.Payload): void;
  }

  export namespace SetFocus {
    export type Payload = string;
  }

  export interface SetProps {
    (payload: SetProps.Payload): void;
  }

  export namespace SetProps {
    export type Payload = Partial<EvaluationsState>;
  }
}

function EvaluationsStore(
  $rootScope: angular.IRootScopeService,
  $api: angular.gears.IApiService,
  $api2: angular.gears.IAPI2Service,
  $acl: angular.gears.IAclService,
  $reincode: angular.gears.IReincodeService
) {
  'ngInject';

  const state: EvaluationsState = {
    loading: false,
    items: [],
    focus: null,
    table: {
      sortedCol: 0,
      searchText: ''
    }
  };

  const getters: GetterTree<EvaluationsState, Root.State> = {
    find: (state) => (ref: string | Evaluation) => {
      return state.items.find((item) =>
        typeof ref === 'string' ? item.id === ref : item === ref
      );
    },
    getById: (state) => (id: string) => {
      return state.items.find((item) => item.id === id);
    },
    getByClientId: (state) => (clientId: string) => {
      return state.items.filter((item) => item.clientId == clientId);
    }
  };

  const actions: ActionTree<EvaluationsState, Root.State> = {
    async list(ctx) {
      let data;

      const grnProfile = ctx.rootState.permissions.profile;

      if (!grnProfile) {
        throw new Error(
          'No permissions profile for the current user was found.'
        );
      }

      // check to see if we're restricted to specific SubGroups
      // and get the list from policy
      let clientResources: ResourceMapValue | null = null;
      let canListSubgroupsClientsEvaluations: boolean | null = null;

      if (!$acl('GM:ListEvaluations', grnProfile)) {
        // Not a GEARS Admin
        clientResources = ctx.rootGetters['permissions/getResources'](
          'institutionmanager:ListClients',
          'subGroup'
        );

        canListSubgroupsClientsEvaluations =
          clientResources &&
          ((typeof clientResources === 'object' &&
            Object.getOwnPropertyNames(clientResources).length > 0) ||
            clientResources === '*');
      }

      if ($acl('GM:ListEvaluations', grnProfile)) {
        // List All Evaluations

        data = await $api2.gm.listEvaluations();
      } else if ($acl('IM:ListEvaluations', grnProfile)) {
        // List All Institution Evaluations

        data = await $api2.im.listEvaluations({
          institutionId: ctx.rootState.me.institution?.id
        });
      } else if (
        $acl('IM:ListClients', grnProfile) &&
        $acl('CM:ListClientEvaluations', grnProfile) &&
        canListSubgroupsClientsEvaluations
      ) {
        // List All Evaluations for a list of Sub Groups

        let subgroupIds;

        if (clientResources && typeof clientResources === 'object') {
          subgroupIds = Object.values(clientResources)
            .map(({ resourceValue }) => resourceValue)
            .filter((val) => typeof val === 'string');
        } else {
          subgroupIds = '*';
        }

        data = await $api2.im.listSubGroupClientsEvaluations({
          institutionId: ctx.rootGetters.activeInstId,
          subgroupIds
        });
      } else if (
        ctx.rootState.clients?.items?.length &&
        $acl('CM:ListClientEvaluations', grnProfile)
      ) {
        // List All Evaluations for a list of Clients
        data = [];

        for (const client of ctx.rootState.clients.items) {
          if (!client.subGroup?.id) {
            console.error('[evaluations/list] client must have a sub group');

            continue;
          }

          const res = await $api2.cm.listClientEvaluations({
            institutionId: client.institution?.id,
            subGroupId: client.subGroup?.id,
            clientId: client.id
          });

          data.push(...res);
        }
      }

      ctx.commit('set', data);
      ctx.dispatch('analytics/computeForEvaluations', null, true);

      return ctx.state.items;
    },
    async get(ctx, options: Actions.Get.Payload) {
      let data = await $api2.cm.getClientEvaluation({
        institutionId: options.instId,
        subGroupId: options.sbGrpId,
        clientId: options.clntId,
        evaluationId: options.evalId
      });

      if (
        data.evaluationData &&
        typeof data.evaluationData.clientId !== 'string'
      ) {
        data.evaluationData.clientId = data.evaluationData.clientId.toString();
      }

      data = $reincode.fullObject(data);

      ctx.commit('add', data);

      return data;
    },
    async getAll(ctx) {
      let data: Evaluation[] = [];
      let error: Error | null = null;

      ctx.commit('setProps', { loading: true });

      try {
        data = await $api2.gm.listEvaluations();
      } catch (err) {
        error = err as Error;
      }

      ctx.commit('setProps', { loading: false });

      if (error) {
        throw error;
      }

      ctx.commit('set', data);

      ctx.dispatch('analytics/computeForEvaluations', null, true);

      return state.items;
    },
    async getForUser(ctx, userId?: Actions.GetForUser.Payload) {
      if (typeof userId !== 'number') {
        if (ctx.rootState.me.id !== false) {
          userId = ctx.rootState.me.id;
        } else {
          throw new Error(
            'A valid user ID was not provided and could not be inferred.'
          );
        }
      }

      let data: Evaluation[] = [];
      let error: Error | null = null;

      try {
        data = (await $api.users.getEvaluations(userId))?.data;
      } catch (err) {
        error = err as Error;
      }

      if (error) {
        ctx.commit('setProps', { loading: false });

        throw error;
      }

      ctx.commit('set', data);

      return state.items;
    },
    async getForClient(ctx, clientId: Actions.GetForClient.Payload) {
      const client = ctx.rootGetters['clients/getById'](clientId);

      if (!client) {
        console.warn(
          `[ngStore:evaluations:getForClient] Client with id "${clientId}" could not be found.`
        );

        return;
      }

      const institutionId = client?.institution?.id ?? client?.institutionId;
      const subGroupId = client?.subGroup?.id;

      if (!institutionId || !subGroupId || !clientId) {
        console.warn(
          `[ngStore:evaluations:getForClient] Client must have a Client ID, Institution ID, and SubGroup ID.`
        );

        return;
      }

      let data: Evaluation[] = [];
      let error: Error | null = null;

      ctx.commit('setProps', { loading: true });

      try {
        data = await $api2.cm.listClientEvaluations({
          institutionId,
          subGroupId,
          clientId
        });
      } catch (err) {
        error = err as Error;
      }

      ctx.commit('setProps', { loading: false });

      if (error) {
        throw error;
      }

      if (!data.length) return;

      ctx.commit('add', data);

      // Compute analytics for evaluation with client data
      ctx.dispatch(
        'analytics/computeForEvaluations',
        { tableData: true, client },
        true
      );

      ctx.commit(
        'clients/updateItem',
        { ref: client, props: { evaluations: data } },
        true
      );

      // return state.items;
      return data;
    },
    async getForInstitution(ctx, instId?: Actions.GetForInstitution.Payload) {
      const institutionId = instId ?? ctx.rootState.me.institution?.id;

      if (!institutionId) return;

      let data: Evaluation[] = [];
      let error: Error | null = null;

      ctx.commit('setProps', { loading: true });

      try {
        data = await $api2.im.listEvaluations({ institutionId });
      } catch (err) {
        error = err as Error;
      }

      ctx.commit('setProps', { loading: false });

      if (error) {
        throw error;
      }

      state.items = [];

      ctx.commit('set', data);
      ctx.dispatch('analytics/computeForEvaluations', { growth: true }, true);

      return state.items;
    },
    async submit(ctx, userId: Actions.Submit.Payload) {
      let data: Evaluation[] = [];
      let error: Error | null = null;

      try {
        data = (await $api.users.getEvaluations(userId))?.data;
      } catch (err) {
        error = err as Error;
      }

      if (error) {
        ctx.commit('setProps', { loading: false });

        throw error;
      }

      ctx.commit('set', data);

      return state.items;
    },
    async delete(ctx, payload: Actions.Delete.Payload) {
      if (!payload) {
        throw console.error('Evaluation - Delete : No Payload provided');
      }

      // Try to find institution Id if not provided on the payload
      if (!payload.institutionId && ctx.rootState.activeInstId) {
        payload.institutionId = ctx.rootState.activeInstId;
      }

      if (
        !payload.institutionId &&
        payload.clientId &&
        find(ctx.rootState.clients.items, { id: payload.clientId })
      ) {
        payload.institutionId = find(ctx.rootState.clients.items, {
          id: payload.clientId
        }).account?.id;
      }

      if (!payload.institutionId) {
        throw console.error('Evaluation - Delete : No Institution ID provided');
      }

      await $api2.im.deleteEvaluation({
        institutionId: payload.institutionId,
        clientId: payload.clientId,
        evalId: payload.id
      });

      remove(ctx.state.items, { id: payload.id });
    }
  };

  const mutations: MutationTree<EvaluationsState> = {
    set(state, payload: Mutations.Set.Payload) {
      payload = (Array.isArray(payload) ? payload : [payload]).map(
        applyConveniencePropsToEvaluation
      );

      state.items = payload;

      $rootScope.$broadcast('evaluationsSet');
    },
    add(state, payload: Mutations.Add.Payload) {
      payload = (Array.isArray(payload) ? payload : [payload]).map(
        applyConveniencePropsToEvaluation
      );

      state.items = uniqBy([...payload, ...state.items], 'id');

      $rootScope.$broadcast('evaluationsSet');
    },
    updateItem(state, payload: Mutations.UpdateItem.Payload) {
      const item = state.getters.find(payload.ref);

      if (!item) return;

      Object.keys(payload.props).forEach((key) => {
        item[key] = payload.props[key];
      });
    },
    remove({ items }, payload: unknown) {
      items.splice(
        items.findIndex((item) => item.id === payload),
        1
      );
    },
    setFocus(state, id: Mutations.SetFocus.Payload) {
      state.focus = state.items.find((item) => item.id === id) || null;
    },
    setProps(state, payload: Mutations.SetProps.Payload = {}) {
      for (const i in payload) {
        if (i in state) {
          state[i] = payload[i];
        }
      }
    },
    CLEAR(state) {
      Object.assign(state, {
        loading: false,
        items: [],
        table: {
          sortedCol: 0,
          searchText: ''
        }
      });
    }
  };

  return {
    state,
    getters,
    actions,
    mutations
  };
}

export default EvaluationsStore;

//#region Helper Functions

/**
 * Apply convenience properties to an `Evaluation` object.
 *
 * @param evl The `Evaluation` object to apply convenience properties to.
 * @returns The same evaluation object with convenience properties applied.
 */
function applyConveniencePropsToEvaluation(evl: Evaluation) {
  if (evl.client) {
    evl.client.fullName = `${evl.client.lName}, ${evl.client.fName}`;
  }

  if (evl.evaluator) {
    evl.evaluator.fullName = `${evl.evaluator.lName}, ${evl.evaluator.fName}`;
  }

  if (typeof evl.clientId !== 'string') {
    evl.clientId = (evl.clientId ?? evl.client?.id)?.toString();
  }

  if (evl.evaluation && typeof evl.evaluation.clientId === 'number') {
    evl.evaluation.clientId = evl.evaluation.clientId.toString();
  }

  if (evl.evaluationData && typeof evl.evaluationData.clientId === 'number') {
    evl.evaluationData.clientId = evl.evaluationData.clientId.toString();
  }

  if (evl.client?.institutionId) {
    evl.institutionId = evl.client.institutionId;
  } else if (evl.client?.accountId) {
    evl.institutionId = evl.client.accountId;
  }

  if (!evl.toolUsed && evl.tool?.id) {
    evl.toolUsed = evl.tool.id;
  }

  return evl;
}

//#endregion Helper Functions
