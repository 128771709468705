import angular from 'angular';
import _ from 'lodash';
import { State, Getter, Action, Mutation } from 'angular-store';

import type { SearchEvaluationRequestsOptions } from '@api/modules/institution-manager';
import type { Evaluation } from '@interfaces/evaluation';
import { Controller, Inject, On } from '@decorators/ngCtrl';
import type { ClientModel } from '@models/client.model';
import type { Root } from '@store';
import type * as ClientStore from '@store/modules/clients';
import type * as EvaluationStore from '@store/modules/evaluations';
import type * as InstitutionStore from '@store/modules/institutions';
import type * as PermissionStore from '@store/modules/permissions';
import { isBefore } from 'date-fns';

import type * as ProgressiveTable from '@components/display-table-progressive/display-table-progressive.component';

/** prohibits editing of YLS and LS suite of tools. */
const prohibitEdit = [
  { id: 4, name: 'LSI-R: SV' },
  { id: 105, name: 'YLS/CMI:SRV' },
  { id: 120, name: 'YLS/CMI 2.0' },
  { id: 124, name: 'LS/CMI' },
  { id: 157, name: 'LSI-R' },
  { id: 188, name: 'ACE Questionnaire' }
];

type TableContextType =
  | 'all-evaluations'
  | 'institution-evaluations'
  | 'institution-sub-group-clients-evaluations'
  | 'client-evaluations';

interface TableQueryParams {
  order: 'ASC' | 'DESC';
  startDate: Date | null;
  endDate: Date | null;
}

type TableLoader = ProgressiveTable.LoaderFunction<Evaluation>;

@Controller
class DashboardEvaluationsView {
  tableContextType: TableContextType | null = null;

  tableProps: ProgressiveTable.TableProperty<Evaluation>[] = [];
  tableItems: Evaluation[] = [];
  tableActions: ProgressiveTable.TableAction<Evaluation>[] = [];

  tableStartDate: Date | null = null;
  tableEndDate: Date | null = null;
  tableOrder: 'ASC' | 'DESC' = 'DESC';

  searchText = '';
  searchParams: TableQueryParams = {
    order: 'DESC',
    startDate: null,
    endDate: null
  };

  selectedInstitution: string | null = null;

  client: ClientModel | null = null;
  loadingInstitutions = false;
  loadingTable = false;

  tableLoader: TableLoader | null = null;

  @Inject readonly $scope!: angular.IScope;
  @Inject readonly $rootScope!: angular.IRootScopeService;
  @Inject readonly $location!: angular.ILocationService;
  @Inject readonly $state!: angular.ui.IStateService;
  @Inject readonly $store!: angular.gears.IStoreService;
  @Inject readonly $modals!: angular.gears.IModalsService;
  @Inject readonly $api!: angular.gears.IApiService;
  @Inject readonly $api2!: angular.gears.IAPI2Service;
  @Inject readonly $auth!: angular.gears.IAuthService;
  @Inject readonly evlUtils!: angular.gears.IEvalUtilsService;
  @Inject readonly reportUtils!: angular.gears.IReportUtilsService;
  @Inject readonly dataModals!: angular.gears.IDataModalsService;
  @Inject readonly notify!: angular.gears.INotifyService;
  @Inject readonly $filter!: angular.IFilterService;

  @State readonly evaluations!: Root.State['evaluations'];
  @State readonly institutions!: Root.State['institutions'];
  @State readonly clients!: Root.State['clients'];
  @State readonly me!: Root.State['me'];
  @State readonly showIdInActions!: Root.State['showIdInActions'];

  @Getter('clients/getById')
  readonly getClient!: ClientStore.Getters.GetById;
  @Getter('evaluations/getById')
  readonly getById!: EvaluationStore.Getters.GetById;
  @Getter('evaluations/getByClientId')
  readonly getEvalsByClientId!: EvaluationStore.Getters.GetByClientId;
  @Getter('permissions/getResources')
  readonly getPermissionsRecourses!: PermissionStore.Getters.GetResource;
  @Getter
  readonly isAdmin!: boolean;

  @Action('clients/list')
  readonly listClients!: ClientStore.Actions.List;
  @Action('evaluations/list')
  readonly listEvaluations!: EvaluationStore.Actions.List;
  @Action('evaluations/getAll')
  readonly getAllEvals!: EvaluationStore.Actions.GetAll;
  @Action('evaluations/getForClient')
  readonly getClientEvals!: EvaluationStore.Actions.GetForClient;
  @Action('evaluations/getForInstitution')
  readonly getInstitutionEvals!: EvaluationStore.Actions.GetForInstitution;
  @Action('institutions/getAll')
  readonly getAllInstitution!: InstitutionStore.Actions.GetAll;

  @Mutation('clients/SET_FOCUS')
  readonly setClientFocus!: ClientStore.Mutations.SetFocus;
  @Mutation('evaluations/add')
  readonly addEvals!: EvaluationStore.Mutations.Add;
  @Mutation('evaluations/setFocus')
  readonly setEvalFocus!: EvaluationStore.Mutations.SetFocus;

  // @On('evaluationsSet')
  // onEvaluationSet() {
  //   console.log('evaluatsion set on dashboard evals');
  //   if (this.activeTableType !== 'progressive') {
  //     this.tableSetup();
  //   }
  // }

  @On('selectedInstitution')
  onSelectedInstitutionChanged() {
    if (this.activeTableType === 'progressive') {
      this.tableLoader = this.createProgressiveTableLoader();
    }
  }

  /**
   * Returns the active table type based on the current table context.
   */
  get activeTableType() {
    return this.tableContextType === 'institution-evaluations' ||
      (this.tableContextType === 'all-evaluations' && this.selectedInstitution)
      ? 'progressive'
      : 'basic';
  }

  get clientId() {
    return ((this.$location.search() as GenericObject<string>)?.clientId ??
      null) as string | null;
  }

  get loadingData() {
    let text = null;

    if (this.evlUtils.loadingTool) {
      text = 'Loading Tool';
    } else if (this.evlUtils.processing) {
      text = 'Processing Evaluation';
    } else if (this.reportUtils.$loading) {
      text = 'Report Loading';
    } else if (this.reportUtils.$generatingPDF) {
      text = 'Generating PDF Report';
    }

    return { loading: !!text, text };
  }

  /**
   * Determines if the basic table should be displayed.
   */
  get showBasicTable() {
    return !this.loadingData.loading && this.activeTableType === 'basic';
  }

  /**
   * Determines if the progressive table should be displayed.
   */
  get showProgressiveTable() {
    return (
      !this.loadingData.loading &&
      this.activeTableType === 'progressive' &&
      this.tableLoader
    );
  }

  get processingEval() {
    return this.evlUtils.processing;
  }

  get loadingTool() {
    return this.evlUtils.loadingTool;
  }

  get reportUtilsLoading() {
    return this.reportUtils.$loading;
  }

  get reportUtilsGeneratingPDF() {
    return this.reportUtils.$generatingPDF;
  }

  get searchBarPlaceholderText() {
    return this.showProgressiveTable ? 'Case Sensitive' : 'Search';
  }

  async $onInit() {
    // Set the the client focus if a client ID is present as a query parameter
    if (this.clientId) {
      this.setClientFocus(this.clientId ? this.clientId.toString() : null);
    }

    // set default date range to 2 year
    const today = new Date();
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 2);
    this.tableStartDate = oneYearAgo;
    this.tableEndDate = today;

    // Initialize table properties (columns) actions, and context type.
    this.tableProps = createTableProps(this);
    this.tableActions = createTableActions(this);
    this.tableContextType = this.getTableContextType();

    // Effective computed property behavior for `searchParams` -- this is
    // because search params `startDate` and `endDate` must both be defined
    // in order to be a valid submission, so those values should only be
    // updated in the component (to trigger a new query) when both are set.
    this.$scope.$watchGroup(
      [
        () => this.tableStartDate,
        () => this.tableEndDate,
        () => this.tableOrder
      ],
      () => {
        const order = this.tableOrder;

        let startDate: Date | null;
        let endDate: Date | null;

        if (this.tableStartDate && this.tableEndDate) {
          startDate = this.tableStartDate;
          endDate = this.tableEndDate;
        } else {
          startDate = null;
          endDate = null;
        }

        this.searchParams = { order, startDate, endDate };
      }
    );

    await this.initializeTableState();

    this.$scope.$watch('vm.tableContextType', (newValue, oldValue) => {
      if (newValue !== oldValue) this.initializeTableState();
    });

    if (this.isAdmin) {
      this.$scope.$watch('vm.selectedInstitution', (newValue, oldValue) => {
        if (newValue !== oldValue) this.onSelectedInstitutionChanged();
      });
    }

    this.$scope.$watch(
      () => this.evaluations.items,
      (value) => {
        this.tableItems = value;
      }
    );
  }

  //#region Public Methods

  /**
   * Load all existing institutions from the database (GEARS admin only).
   */
  async loadInstitutions() {
    if (this.institutions?.items?.length) return;

    this.loadingInstitutions = true;

    try {
      await this.getAllInstitution();
    } catch (err) {
      this.notify.display(err, 'error');
    }

    this.loadingInstitutions = false;
  }

  /**
   * Display a table that shows all existing evaluations from the database
   * (GEARS admin only).
   */
  async displayAllEvaluationsTable() {
    this.selectedInstitution = null;

    await this.getAllEvals();

    this.tableItems = this.evaluations.items;
  }

  /**
   * Initializes the table state by loading the necessary data and applying
   * proper state/
   */
  async initializeTableState() {
    this.$store.commit('setLoadingMessage', 'Retrieving Evaluations...');
    this.$store.commit('setIsLoading', true);

    this.loadingTable = true;

    // Ensures that all client data is loaded in the store for future
    // referencing by various table actions.
    if (!this.isAdmin) await this.listClients();

    await this.tableSetup();

    this.loadingTable = false;

    this.$store.commit('setIsLoading', false);

    this.$scope.$apply();
  }

  //#region View Actions

  async createEvaluation() {
    if (!this.client) {
      if (!this.clients?.items?.length) await this.listClients();

      this.client = await this.$modals.settings.chooseClient(
        this.clients?.items
      );
    }

    if (!this.client) {
      this.notify.display(
        'Must select a client to start an evaluation.',
        'warning'
      );

      return;
    }

    this.evlUtils.startNewEvaluation(this.client);
  }

  async createEvaluationRequest() {
    await this.$modals.create.evaluationRequest(this.clients);
  }

  reportsOpen(val: Evaluation) {
    let clientId = this.client?.id;
    if (!clientId) clientId = val.client?.id;
    if (!clientId) clientId = val.clientId;

    this.setClientFocus(clientId ? clientId.toString() : null);
    this.setEvalFocus(val.id);

    this.reportUtils.evlForReport = val;
    this.reportUtils.openReports(val);
  }

  // get reportUtilsLoading() {
  //   return this.reportUtils.isLoading();
  // }

  async evalView(val: Evaluation) {
    this.$state.go('dashboardViewEvaluation', { evalId: val.id });
  }

  evalEdit(val: unknown) {
    this.evlUtils.editCompletedEvaluationWarning(val);
  }

  async evalDelete(val: unknown) {
    this.dataModals.delete(val, 'evaluation');
    // this.evlUtils.delete(val, 'evaluation');
  }

  evalContinue(val: unknown) {
    this.evlUtils.continueEvaluation(val);
  }

  evalRevert(val: unknown) {
    this.evlUtils.revertEvaluationWarning(val);
  }

  evalBulkCreate() {
    this.$modals.create.bulkEvaluations(this.me.institution?.id);
  }

  async evalReassessment(evalRef: unknown) {
    this.evlUtils.startReassessment(evalRef);
  }

  goToClientProfile() {
    this.$state.go('dashboardClient', { id: this.client?.id?.toString() });
  }

  //#endregion View Actions

  //#endregion Public Methods

  /**
   * Sets up the table based on the current table context.
   */
  private async tableSetup() {
    let tableItems: Evaluation[] = [];
    let tableLoader: TableLoader | null = null;

    if (this.clientId) {
      this.client = this.getClient(this.clientId) ?? null;

      if (this.client) {
        this.client.evaluations =
          (await this.getClientEvals(this.client.id)) ?? [];
      }

      tableItems = this.client?.evaluations ?? [];
    } else if (this.activeTableType === 'progressive') {
      tableLoader = this.createProgressiveTableLoader();
    } else if (this.tableContextType !== 'all-evaluations') {
      await this.listEvaluations();

      tableItems = this.evaluations.items;
    }

    this.tableItems = tableItems;
    this.tableLoader = tableLoader;

    this.$store.commit('reverseTableSort', true);
  }

  //#region Helper Functions

  /**
   * Determines the type of table to display based on the user's permissions.
   */
  private getTableContextType() {
    if (this.clientId) {
      return 'client-evaluations';
    }

    if (this.$auth.hasAccess('GM:ListEvaluations')) {
      return 'all-evaluations';
    }

    if (this.$auth.hasAccess('IM:ListEvaluations')) {
      return 'institution-evaluations';
    }

    return 'institution-sub-group-clients-evaluations';
  }

  /**
   * Creates a progressive table loader function for fetching evaluations.
   *
   * @returns A progressive table loader function.
   */
  private createProgressiveTableLoader() {
    return (async (ctx) => {
      const institutionId =
        this.tableContextType !== 'all-evaluations'
          ? this.me.institution?.id
          : this.selectedInstitution;

      if (!institutionId) {
        return { items: [], lastEvaluatedKey: null };
      }

      const options: SearchEvaluationRequestsOptions = {
        institutionId,
        limit: ctx.minLimit ?? 10
      };

      if ('startKey' in ctx) {
        options.startKey = ctx.startKey;
      }

      if (ctx.queryText) {
        options.searchTerm = ctx.queryText;
      }

      if (
        ctx.queryParams?.startDate instanceof Date &&
        ctx.queryParams?.endDate instanceof Date
      ) {
        options.startDate = ctx.queryParams.startDate.toISOString();
        options.endDate = ctx.queryParams.endDate.toISOString();

        // validate date params
        if (isBefore(new Date(options.endDate), new Date(options.startDate))) {
          this.notify.display('Start Date must be before End Date', 'warning');
          return;
        }
      }

      if (
        ctx.queryParams?.order === 'ASC' ||
        ctx.queryParams?.order === 'DESC'
      ) {
        options.order = ctx.queryParams.order;
      }

      const res = await this.$api2.im.searchEvaluations(options);

      // Add the fetched evaluations to the store.
      this.addEvals(res.items);

      return res;
    }) as TableLoader;
  }

  //#endregion Helper Functions
}

export default DashboardEvaluationsView;

//#region Helper Functions

function createTableProps(vm: DashboardEvaluationsView) {
  return [
    {
      label: 'Assigned Date',
      value: 'assignedDate',
      filter: { type: 'date', format: 'MM/dd/yyyy hh:mm a' }
    },
    {
      label: 'Completed Date',
      value: ({ status, updatedAt }) => {
        return !updatedAt || status !== 'COMPLETED' ? 'N/A' : updatedAt;
      },
      filter: { type: 'date', format: 'MM/dd/yyyy hh:mm a' }
    },
    {
      label: vm.$filter('clientLabel')('localId', true),
      value: 'client.localId'
    },
    {
      label: 'Client Name',
      value: ({ client }) => {
        let output = '';

        if (client?.fullName) {
          output = client.fullName;
        } else if (client?.lName && client?.fName) {
          output = `${client.lName}, ${client.fName}`;
        }

        return output;
      }
    },
    {
      label: 'Status',
      value: (row) => {
        let output = '';

        if (row.status === 'IN_PROGRESS') {
          output = `<label class="status-label in-progress">In Progress</label>`;
        } else if (row.status === 'NOT_STARTED') {
          output = `<label class="status-label not-started">Not Started</label>`;
        } else if (row.status === 'COMPLETED') {
          output = `<label class="status-label completed">Completed</label>`;
        }

        return output;
      }
    },
    {
      label: 'Tool',
      value: 'tool.name'
    },
    {
      label: 'Due Date',
      value: 'dueDate',
      filter: { type: 'date', format: 'MM/dd/yyyy' }
    }
  ] as ProgressiveTable.TableProperty<Evaluation>[];
}

function createTableActions(vm: DashboardEvaluationsView) {
  return [
    {
      label: 'Options',
      icon: 'cog',
      actions: [
        {
          label: 'View Reports',
          icon: 'fileWord',
          fn: (val) => vm.reportsOpen(val),
          disabled: (val) => val.status !== 'COMPLETED'
        },
        {
          label: 'View',
          icon: 'eye',
          fn: (val) => vm.evalView(val, 'evaluations'),
          disabled: (val) => val.status !== 'COMPLETED'
        },
        {
          label: 'Edit',
          icon: 'edit',
          fn: (val) => vm.evalEdit(val),
          disabled: (val) => {
            if (_.find(prohibitEdit, { id: val.toolUsed })) return true;
            return val.status !== 'COMPLETED';
          }
        },
        {
          label: 'Continue',
          icon: 'arrowCircleRight',
          fn: (val) => vm.evalContinue(val),
          disabled: (val) => val.status === 'COMPLETED'
        },
        {
          label: 'Reassessment',
          icon: 'redo',
          fn: (val) => vm.evalReassessment(val),
          disabled: (val) => {
            // Disable if...
            // • Institution reassessment option is “Not Allowed”, or...
            // • Evaluation is NOT Completed, or...
            // • Tool used for Evaluation does not all for reassessments

            // return (
            //   !this.institution.reassessmentAllowed ||
            //   !val.reassessmentAllowed ||
            //   val.status !== 'COMPLETED'
            // );

            return (
              vm.me.institution?.reassessmentOption != 'NOT_ALLOWED' &&
              val.status !== 'COMPLETED'
            );
          }
        },
        {
          label: 'Manage Media',
          icon: 'file-alt',
          fn: (data) => {
            const client = vm.clients.items.find(
              (item) => item.id === data.clientId
            );

            vm.$modals.evaluation.manageMedia({
              institutionId: client?.institutionId,
              subGroupId: client?.subGroup?.id,
              clientId: client?.id,
              evaluationId: data.evaluationId ? data.evaluationId : data.id
            });
          }
        },
        {
          label: 'Revert',
          icon: 'history',
          fn: (val) => vm.evalRevert(val),
          disabled: (val) => {
            if (_.find(prohibitEdit, { id: val.toolUsed })) return true;

            return (
              val.status === 'NOT_STARTED' ||
              val.status === 'IN_PROGRESS' ||
              !val.backup
            );
          }
        },
        {
          label: 'Delete',
          icon: 'trashAlt',
          fn: (val) => vm.evalDelete(val),
          hide: () => {
            return !vm.$auth.hasAccess('CM:DeleteClientEvaluation');
          },
          disabled: (val) => {
            return (
              !!_.find(prohibitEdit, { id: val.toolUsed }) &&
              val.status === 'COMPLETED'
            );
          }
        }
      ]
    }
  ] as ProgressiveTable.TableAction<Evaluation>[];
}

//#endregion Helper Functions
